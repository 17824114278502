<template>
    <div ref="rootNode">
        <div />
    </div>
    <Camera ref="camera" />
</template>

<script>

    import * as THREE from 'three';
    import { emits, inject, onMounted, onUnmounted, ref } from 'vue';
    import { MeshSurfaceSampler } from 'three/addons/math/MeshSurfaceSampler.js';
    import textureMap from '../assets/furmap.png';
    import Camera from './Camera.vue';
    
    export default {
        name: 'FurryMesh',
        "components": {
            Camera
        },
        "props": {
            params: {
                type: Object,
                required: true,
            },
        },
        "emits": [
        ],
        setup(props, { emit }) {

            const scene = inject('scene'),
                rootNode = ref(null),
                strands = ref([]),
                furMap = ref(),
                camera = ref(null),
                generateFur = (surfaceMesh) => {

                    console.log("surfaceMesh.")
                    console.log(surfaceMesh)

                    const sampler = new MeshSurfaceSampler(surfaceMesh)//.setWeightAttribute('uv')
                        .build();

                    const position = new THREE.Vector3();
                    const normal = new THREE.Vector3();
                    
                    //let vertices = new Float32Array(1000);
                    let vertices = [];
                    let strandStart = new Float32Array(3 * 1000);
                    let strandEnd = new Float32Array(3 * 1000);

                    for (let i = 0, j = 0; i < 100 * 3; i+=3, j++) {

                        sampler.sample(position, normal);
                        normal.add(position);
                        strands.value.push(position.clone());
                        strands.value.push(normal.clone());

                        let p = position.clone();
                        let n = normal.clone();

                        //vertices[j] = new THREE.Vector3(p.x, p.y, p.z);
                        vertices[j] = p;

                        strandStart[i] = p.x;
                        strandStart[i+1] = p.y;
                        strandStart[i+2] = p.z;

                        strandEnd[i] = n.x;
                        strandEnd[i+1] = n.y;
                        strandEnd[i+2] = n.z;

                    }
                    
                    //const furGeometry = new THREE.BufferGeometry();
                    //const furGeometry = new THREE.BufferGeometry().setFromPoints(strands.value);

                    //const positions = surfaceMesh.geometry.attributes.position.array;
                    //const vertices = [];

                    // Collect vertices
                    /*
                    for (let i = 0; i < positions.length; i += 3) {

                        vertices.push(positions[i], positions[i + 1], positions[i + 2]);

                    }

                    // Create BufferGeometry and set attributes
                    const furGeometry = new THREE.BufferGeometry();
                    furGeometry.setAttribute('position', new THREE.BufferAttribute(surfaceMesh.geometry.attributes.position.array, 3));
                    furGeometry.setAttribute('normal', new THREE.BufferAttribute(surfaceMesh.geometry.attributes.normal.array, 3));
                    furGeometry.setAttribute('skinIndex', new THREE.BufferAttribute(surfaceMesh.geometry.attributes.skinIndex.array, 4));
                    furGeometry.setAttribute('skinWeight', new THREE.BufferAttribute(surfaceMesh.geometry.attributes.skinWeight.array, 4));
                    furGeometry.setAttribute('uv', new THREE.BufferAttribute(surfaceMesh.geometry.attributes.uv.array, 2));
                    furGeometry.index = surfaceMesh.geometry.index;
                    */


                    // sphere.material.map = texture;
                    // const furGeometry = new THREE.BufferGeometry().setFromPoints(surfaceMesh.geometry.attributes.position.array);


                    const furGeometry = new THREE.BufferGeometry().setFromPoints(vertices);

                    const furMaterial = new THREE.ShaderMaterial({
                        uniforms: {
                            time: { value: 1.0 },
                            lightDirection: { value: new THREE.Vector3(1, 1, 1) },
                            furTexture: { value: furMap.value },
                            cameraposition: { type: 'v3', value: camera.value.camera.position }
                        },
                        vertexShader: `

                            varying vec3 vPosition;
                            varying vec3 vNormal;
                            varying vec3 bPosition;
                            varying vec3 vStrandStart;
                            varying vec3 vStrandEnd;
                            uniform vec3 cameraposition;

                            varying vec3 entryPoint;
                            varying vec3 exitPoint;
                            varying vec3 depthOccupied;
                            varying vec3 vCameraPosition;
                            varying float vRad;

                            void main() {

                                vPosition = position;
                                vNormal = -normal;
                                vCameraPosition = cameraPosition;

                                float rad = distance(cameraPosition, position);

                                vRad = rad;

                                if (mod(float(gl_VertexID), 2.0) == 0.0) {
                                    vStrandStart = position;
                                } else {
                                    vStrandEnd = position;
                                }


                                gl_PointSize = distance(cameraPosition, position) / 2.0 + 10.0;
                                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

                            }

                        `,
                        fragmentShader: `

                            uniform sampler2D furTexture;
                            varying vec3 vPosition;
                            varying vec3 vNormal;
                            varying vec3 depthOccupied;

                            varying vec3 entryPoint;
                            varying vec3 exitPoint;
                            varying float vRad;

                            // uniform vec3 cameraPosition;

                            const int steps = 10;
                            const float stepSize = 0.1;

                            vec4 sampleFur(vec3 start, vec3 dir) {
                                vec4 color = vec4(0.0);
                                float alpha = 1.0;

                                for (int i = 0; i < steps; i++) {
                                    vec3 p = start + float(i) * stepSize * dir;
                                    vec2 uv = p.xy * 0.5 + 0.5;  // Convert to UV coordinates
                                    vec4 fur = texture2D(furTexture, uv);

                                    // Beer's Law
                                    color.rgb += fur.rgb * alpha * fur.a;
                                    alpha *= (1.0 - fur.a);
                                }

                                color.a = 1.0 - alpha;
                                return color;
                            }

                            void main() {
                                vec3 rayDir = normalize(vPosition);
                                vec3 rayStart = vec3(0.0, 0.0, 0.0);  // Adjust as necessary

                                //vec3 normal = gl_FrontFacing ? vNormal : -vNormal;
                                //vec3 entryPoint = gl_FrontFacing ? vPosition : vec3(0.0);
                                //vec3 exitPoint = gl_FrontFacing ? vec3(0.0) : vPosition;

                                //gl_FragColor = sampleFur(rayStart, rayDir);


                                

                                vec3 color = vec3(1.0) * (1.0 - vRad * 0.01);

                                
                                gl_FragColor = vec4(vRad,vRad,vRad, 1.0);
                            }
                        `,
                        side: THREE.DoubleSide
                    });

                    //const furGeometry = new THREE.BufferGeometry();
                    //furGeometry.setAttribute('position', new THREE.BufferAttribute(vertices.value, 3));

                    //surfaceMesh.material = furMaterial;
                    //surfaceMesh.material.needsUpdate = true;

                    //surfaceMesh.visible = false;

                    const furMesh = new THREE.Points(furGeometry, furMaterial);
                    furMesh.scale.set(1.1,1.1,1.1);

                    scene.add(furMesh);

                    /*
                    const pointsMaterial = new THREE.PointsMaterial({color: 0x0000ff, size: 0.01});
                    const pointsGeometry = new THREE.BufferGeometry().setFromPoints(vertices);
                    const points = new THREE.Points(pointsGeometry, pointsMaterial);
                    scene.add(points);
                   

                    const lineMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });
                    const lineGeometry = new THREE.BufferGeometry().setFromPoints(strands.value);
                    const lines = new THREE.LineSegments(lineGeometry, lineMaterial);
                     */
                    //scene.add(lines);

                };

            onMounted(() => {

                const manager = new THREE.LoadingManager();
                const loader = new THREE.TextureLoader(manager);

                loader.load(textureMap, function (texture) {

                    console.log('loaded texture')

                    texture.colorSpace = THREE.SRGBColorSpace;
                    texture.minFilter = THREE.NearestFilter;
                    texture.generateMipmaps = false;
                    furMap.value = texture;

                });

            });

            onUnmounted(() => {
                if (rootNode.value) {
                    // rootNode.value.removeChild(renderer.domElement);
                }
            });

            return {
                scene,
                strands,
                rootNode,
                generateFur,
                furMap,
                camera
            };

        },

    };

</script>

<style scoped>
    div {
        width: 100%;
        height: 100%;
    }
</style>