<template>
  <div
    v-if="isLang('ja') > -1"
    :class="docMain"
  >
    <head>
      <title><b>NFT</b>マーケットプレイス利用規約</title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div>
      <h1 class="doc">
        NFTマーケットプレイス利用規約
      </h1><br>
      本利用規約（以下「本規約」といいます。）は、株式会社マツモト（以下「当社」といいます。）がインターネット上で<br>運営するNFTマーケットプレイス「Shinovi」（以下「本マーケットプレイス」といい、関連するサービスを含めて「本<br>サービス」と総称します。）のご利用について、本サービスのユーザー（第2条第1号で定義します。）及びユーザー<br>になろうとする者が遵守すべき事項並びに当社とユーザー間の権利義務関係を定めるものです。本サービスの<br>利用に際しては、あらかじめ本規約の全文をお読みいただいたうえで、本規約に同意していただく必要がありま<br>す。<br>
      第１章　基本的事項<br>
      第１条（定義）<br>
      本規約上で使用する用語については、以下のとおり定義します。<br>
      （１）「ユーザー」とは、本サービスの利用を行う者をいいます。<br>（２）「ユーザーID」とは、本サービスの利用のためにユーザーが登録するメールアドレスをいいます。<br>（３）「パスワード」とは、ユーザーIDに対応してユーザーが固有に設定する文字列をいいます。<br>（４）「コンテンツ」とは、文章、音声、音楽、画像、動画、ソフトウェア、プログラム、コードその他の情報のこと<br>
      をいいます。<br>
      （５）「デジタルチケット」とは、権利者が開催するイベントに参加する権利等として機能する当社発行の電子<br>
      的な証票をいい、本NFTに化体してユーザーに対して発行・付与された日から180日間に限り、権利者に対して使用することができます。<br>
      （６）「暗号資産」とは、資金決済に関する法律第2条第5項に定める暗号資産をいいます。<br>（７）「本NFT」とは、コンテンツやデジタルチケット等を表章する、ブロックチェーン上で発行される非代替性<br>
      トークン（Non-Fungible&#160;Token）をいいます。<br>
      （８）「当社ウェブサイト」とは、そのドメインが「Shinovi.io」である、当社が運営するウェブサイト（理由の如何<br>
      を問わず、当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。<br>
      （９）「個人情報」とは、個人情報の保護に関する法律第2条第1項により定義される個人情報、すなわち、①<br>
      生存する個人に関する情報であって、当該情報に含まれる氏名その他の記述等により特定の個人を識別することができるもの、及び②生存する個人に関する情報であって、個人識別符号が含まれるものをいいます。なお、個人情報の保護に関する法律に規定する個人情報と同義であり、関係法<br>令が改正された場合には、その定義に従うものとします。<br>
      （10）「登録情報」とは、ユーザーが本サービスの利用登録を行うにあたって当社に提供した情報（その後、<br>
      変更を届け出た情報を含みます。）をいいます。<br>
      （11）「出品」とは、当社が定める方法により本NFTを本マーケットプレイス上で購入することが可能な状態に<br>
      置くことをいいます。<br>
      （12）「出品者」とは、本マーケットプレイス上で本NFTを出品する者をいいます。<br>（13）「権利者」とは、コンテンツに係る知的財産権（第4号において定義します。）やデジタルチケットを表章<br>
      する本NFTを発行し、出品し、販売するために必要な権利を有する者をいいます。<br>
      （14）「本NFT保有者」とは、本マーケットプレイス上で本NFTを購入し、本サービス上で現に本NFTを保有<br>
      しているユーザーをいいます。<br>
      （15）「知的財産」とは、発明、考案、意匠、著作物その他の人間の創造的活動により生み出されるもの、商<br>
      標、商号その他事業活動に用いられる商品又は役務を表示するもの及び営業秘密その他の事業活動に有用な技術上又は営業上の情報をいいます。<br>
      （16）「知的財産権」とは、特許権、実用新案権、意匠権、著作権、商標権その他の知的財産に関して法令<br>
      により定められた権利（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）又は法律上保護される利益に係る権利をいいます。<br>
      （17）「反社会的勢力」とは、以下のいずれかに該当する者をいいます。<br>
      ①暴力団（暴力団員による不当な行為の防止等に関する法律（平成3年法律第77号。以下「暴対<br>
      法」といいます。）第2条第2号に規定する暴力団をいいます。）<br>
      ②暴力団員（暴対法第2条第6号に規定する暴力団員をいいます。）③暴力団員でなくなった時から5年を経過しない者④暴力団準構成員⑤暴力団関係企業<br>⑥総会屋等（総会屋、会社ゴロ等企業等を対象に不正な利益を求めて暴力的不法行為等を行う<br>
      おそれがあり、市民生活の安全に脅威を与える者をいいます。）<br>
      ⑦社会運動等標ぼうゴロ又は政治活動標ぼうゴロ（社会運動若しくは政治活動を仮装し、又は標ぼ<br>
      うして、不正な利益を求めて暴力的不法行為等を行うおそれがあり、市民生活の安全に脅威を与える者をいいます。）<br>
      ⑧特殊知能暴力集団等（上記①から⑦までに掲げる者以外の、暴力団との関係を背景に、その威<br>
      <hr>
      <a name="2" />力を用い、又は暴力団と資金的なつながりを有し、構造的な不正の中核となっている集団又は個人をいう。）<br>
      ⑨その他上記①から⑧までに準ずる者⑩上記①から⑨までに該当する者（以下「暴力団員等」といいます。）が経営を支配していると認め<br>
      られる関係を有する者<br>
      ⑪暴力団員等が経営に実質的に関与していると認められる関係を有する者⑫自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってす<br>
      るなど、不当に暴力団員等を利用していると認められる関係を有する者<br>
      ⑬暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をし、暴力団員等の維持<br>
      運営に積極的に協力していると認められる関係を有する者<br>
      ⑭役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有す<br>
      る者<br>
      第２条（適用範囲）<br>
      １　本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係<br>
      を定めるものであり、当社とユーザーとの間の本サービスの利用に関わる一切の関係に適用されます。<br>
      ２　当社が当社ウェブサイト上に掲載する本サービスの利用に関する利用ルール、及びポリシー等（以下「利用<br>
      ルール等」といいます。）は、本規約の一部を構成します。<br>
      ３　本規約の内容と前項の利用ルール等との間で矛盾又は不一致等がある場合には、当該利用ルール等に<br>
      特段の定めがない限り、本規約の規定が優先します。<br>
      第２章　本マーケットプレイスの利用<br>
      第３条（利用登録）<br>
      １　本サービスの利用を希望する者は、あらかじめ本規約の全文を確認したうえで本規約を遵守することに同<br>
      意する必要があります。また、当社は、当社所定の事項（以下「登録事項」といいます。）に関する情報を当社所定の方法で当社に提供することによる本サービスの利用登録の申請を求めることがあります。<br>
      ２　当社は、前項の規定に基づいて登録を申請した者（以下「登録申請者」といいます。）について登録の適否<br>
      を判断し、その結果を当該登録申請者に通知します。<br>
      ３　前項に基づき当社が登録を認める旨の結果の通知をした場合、当社と当該登録申請者との間で本規約の<br>
      内容に沿った本サービスの利用契約（以下「サービス利用契約」といいます。）が成立し、当該登録申請者は、ユーザーとして、本サービスを本規約に従い利用することができるようになります。<br>
      ４　登録申請者は、自らが未成年者等の制限行為能力者である場合、本規約への同意にあたり事前に法定代<br>
      理人の同意を得なければなりません。登録申請者が未成年者である場合は、法定代理人の同意の有無に関して、当社から当該登録申請者又はその法定代理人に対し、確認の連絡をする場合があります。<br>
      ５　当社は、登録申請者が、次の各号のいずれかに該当する場合、本条第2項の利用登録を拒否することがで<br>
      きます。なお、当社は登録拒否の理由について一切の開示義務を負いません。<br>
      （１）登録申請者が当社に提供した登録事項の全部又は一部につき、虚偽、誤記、又は記載の脱漏があっ<br>
      た場合<br>
      （２）登録申請者が、未成年者、成年被後見人、被保佐人、又は被補助人のいずれかであり、法定代理人、<br>
      後見人、保佐人、又は補助人の同意等を得ていなかった場合<br>
      （３）登録申請者が、反社会的勢力であると当社が判断した場合（４）登録申請者が、過去に当社との契約に違反した者又はその関係者であると当社が判断した場合（５）登録申請者が、過去に当社によりユーザーとしての登録を抹消された者であることが判明した場合（６）登録申請者が、既に利用登録を完了した者と同一人物であると当社が判断した場合（７）登録申請者が、本サービスをマネー・ローンダリングや詐欺等の犯罪行為のために利用するおそれが
      あると合理的に認められる場合<br>
      （８）登録申請者が、法令又は本規約に違反した利用方法で本サービスを利用するおそれがあると合理的<br>
      に認められる場合<br>
      （９）その他、登録申請者について、登録をすることが相当でないと当社が判断した場合<br>
      ６　登録申請時に未成年であったユーザーが、成年に達した後に本サービスを利用した場合、当該ユーザー<br>
      は本サービスに関する一切の法律行為を追認したものとみなされます。<br>
      ７　登録申請者は、自己が、現在、反社会的勢力のいずれにも該当しないこと、かつ将来にわたっても該当し<br>
      ないこと、及び、自ら又は第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、取<br>引に関して脅迫的な言動又は暴力を用いる行為、風説を流布し、偽計を用い又は威力を用いて当社の信<br>用を毀損し又は当社の業務を妨害する行為、その他これらに準ずる行為を行わないことを確約するものとし<br>ます。なお、当社は、ユーザーが反社会的勢力に該当すると判断した場合又は上記確約に反した行為をし<br>た場合、事前に当該ユーザーに通知することなく、本サービスの提供を停止することができ、当社は、これに<br>よって当該ユーザーに生じた損害や不利益について、責任を負いません。<br>
      ８　ユーザーは、登録事項に係る情報に変更が生じた場合は、直ちに登録情報を変更しなければならず、常<br>
      2<br>
      <hr>
      <a name="3" />にユーザー自身の正確な情報が登録情報に反映されているように登録情報を管理及び修正する責任を負<br>います。登録事項に係る情報に変更があったにも関わらず、登録情報の修正が行われていない場合、当社<br>は、登録情報に変更がないものとして取り扱うことができます。変更の届出があった場合でも、当社は、変更<br>登録前に行われた取引や各種手続との関係では、変更前の情報に依拠することができます。<br>
      ９　当社による登録情報の取扱いについては、別途当社が定める個人情報保護方針（<br>
      https://www.matsumoto-inc.co.jp/privacypolicy.html）によるものとし、ユーザーはこの個人情報保護方針に<br>従って当社がユーザーの利用者情報を取り扱うことに同意します。<br>
      10　当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できないような統計的な情報として、<br>
      利用及び公開することができます。<br>
      第４条（パスワード及びユーザー<b>ID</b>の管理）<br>
      １　ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保<br>
      管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはなりません。<br>
      ２　パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する<br>
      責任はユーザー自身がすべて負うものとします。<br>
      ３　登録情報が盗用され又は第三者に利用されていることが判明した場合、ユーザーは直ちにその旨を当社<br>
      に通知し、当社の指示に従うものとします。この場合、当社はそのユーザーIDやパスワードによる本サービス<br>の利用を停止することができ、また、当社は当該利用停止に起因して当該ユーザーに生じた損害につい<br>て、一切の責任を負いません。<br>
      ４　当社は、本サービスの利用があった場合、当該アカウントに係るユーザーが利用したものと扱うことができ、<br>
      当該利用によって生じた結果並びにそれに伴う当社に対する一切の責任については、当該ユーザーに帰<br>属するものとします。<br>
      第５条（ウォレット管理）<br>
      ユーザーは、本サービスを利用するために、本NFTを移転させるためのシードフレーズや秘密鍵（以下「秘密<br>
      鍵等」といいます。）をユーザー自身が管理する当社又は当社以外の第三者が提供する自己管理型ウォレット<br>（以下「本ウォレット」といいます。）を当社が別途定める方法に従って用いるものとします。本ウォレットは、ユー<br>ザーが本NFTを移転させるための秘密鍵等を自ら管理するためのものであり、ユーザーは、本ウォレットの利<br>用にあたって、自己の責任において秘密鍵等の管理を行うものとし、これらが漏えい、滅失又は毀損したことに<br>より当該ユーザーに損害が生じたとしても、当社は当該損害を賠償する責任を一切負いません。<br>
      第６条（本<b>NFT</b>の一次出品）<br>
      １　権利者は、当社に対して本NFTを出品するに必要な一切の権利関係を譲渡又は承諾し、本サービスを通<br>
      じて本NFTを発行し、本マーケットプレイスに出品すること（以下「一次出品」といいます。）を当社に依頼しま<br>す。なお、権利者以外の者は本マーケットプレイスに本NFTを一次出品することを当社に依頼することはで<br>きません。<br>
      ２　権利者は、前項に基づいて本NFTの一次出品を当社に依頼するにあたり、当社が別途定める販売方法に<br>
      従って、①当該本NFTの出品価格を決定し、又は②当該出品価格の決定を当社に委託します。<br>
      ３　当社は前項の①又は②に基づいて定められた出品価格に従って本NFTを当社の名義で一次出品します。<br>
      なお、当社は、本NFTの種別毎に最低出品価格を指定することができます。その場合、権利者は、当該最<br>低出品価格以上の価格で本NFTの売却価格を決定しなければなりません。<br>
      ４　権利者は、本NFTの一次出品を当社に依頼する際、①当社が相当と認める範囲において、当該本NFTの<br>
      保有者に一定の権利を認める場合にはその内容（デジタルデータの利用許諾を含みます。）、当該本NFT<br>の二次出品（第８条において定義します。以下同じ。）の待機期間その他二次出品に関するルール及びロイ<br>ヤリティ（第10条において定義します。以下同じ。）に関する事項（以下「出品条件」といいます。）を設定する<br>こと、又は②当該出品条件の設定を当社に委託することができます。当社は前項に定める一次出品を行う<br>に際し、本項①又は②に基づいて定められた出品条件を付します。<br>
      ５　当社は、第２項及び前項の委託を受けて当社が行った本NFTの出品価格や出品条件の決定・設定に起因<br>
      して当該ユーザーに生じた損害について、一切の責任を負いません。<br>
      ６　権利者は、次の各号に定める本NFTの出品を当社に依頼することはできません。<br>
      （１）法令等に違反し若しくは第三者の権利を侵害するもの、又は法令等に違反する行為若しくは第三者の<br>
      権利を侵害することにより取得したもの<br>
      （２）その保有又は取引が法令等に違反し又は第三者の権利を侵害するもの<br>（３）その発行又は取引が本規約に違反するもの<br>（４）その他当社が不適切であると認めたもの<br>
      ７　権利者は、自身が本サービスを通じて本NFTの一次出品を当社に依頼するために必要な一切の権限を有<br>
      し、これらの行為が第三者の権利を侵害しないことを表明し、保証するものとします。<br>
      ８　第３項に基づいて一次出品された本NFTに関して、第三者による訴訟の提起その他権利者が権利を有し<br>
      ないことを理由とした紛争が発生した場合、権利者は、その責任と負担においてこれに対応するものとし、当<br>社に一切の負担を生じさせないものとします。また、権利者は、当社に対し、当該紛争に起因して当社に発<br>生する損害（合理的な弁護士費用を含みます。）を賠償するものとします。<br>
      3<br>
      <hr>
      <a name="4" />第７条（一次出品における本<b>NFT</b>の売買の成立及び対価の支払等）<br>
      １　ユーザーは、本サービスを通じて、一次出品された本NFTを購入することができます（以下、当該ユーザー<br>
      を「購入者」といいます。）。<br>
      ２　購入者が、当社ウェブサイト上の一次出品された本NFTの販売ページにおいて、当社所定の方法により当<br>
      該本NFTの対価及び手数料を支払った時点で、当社と当該購入者の間で当該本NFTについての売買契<br>約が成立し、当社所定の方法により当該本NFTを購入者の本ウォレットに送付します。<br>
      ３　当社は、当該本NFTの購入金額、購入可能な回数その他当該本NFTの売買に関連する事項について一<br>
      定の制限を設ける場合があり、ユーザーはこれに従うものとします。<br>
      ４　購入者は、当該本NFTについての売買契約が成立した後は、民法、消費者契約法その他の法律により認<br>
      められる場合を除き、当該売買契約の取消しやキャンセル等を行うことはできません。<br>
      第８条（本<b>NFT</b>の二次出品）<br>
      １　本NFT保有者は、本マーケットプレイスにおいて当社又は他の出品者から購入した本NFTを、本マーケット<br>
      プレイスを通じて再び出品することができます（以下「二次出品」といい、二次出品された本NFTを本条及び<br>次条において「二次出品NFT」といいます。）。ただし、権利者が第6条第4項により二次出品の待機期間を<br>設定している場合、本NFT保有者は、当該期間中は二次出品を行うことはできません。<br>
      ２　本NFT保有者は、前項に基づいて本NFTの二次出品をするにあたり、当社が別途定める販売方法に従っ<br>
      て、当該本NFTの出品価格を決定します。なお、当社は、本NFTの種別毎に最低出品価格を指定すること<br>ができます。その場合、本NFT保有者は、当該最低出品価格以上の価格で本NFTの出品価格を決定しな<br>ければなりません。<br>
      ３　本NFT保有者は、次条第2項に定める本NFT保有者と当社との間での二次出品NFTについての売買契約<br>
      が成立する前に限り、当社所定の方法により、二次出品NFTの二次出品を取り下げることができます。<br>
      ４　本NFT保有者は、次の各号に定める本NFTを出品することはできません。<br>
      （１）法令等に違反し若しくは第三者の権利を侵害するもの、又は法令等に違反する行為若しくは又は第三<br>
      者の権利を侵害することにより取得したもの<br>
      （２）その保有又は取引が法令等に違反し又は第三者の権利を侵害するもの<br>（３）その発行又は取引が本規約に違反するもの<br>（４）その他当社が不適切であると認めたもの<br>
      ５　本NFT保有者は、自身が本サービスを通じて本NFTの二次出品をするために必要な一切の権限を有し、こ<br>
      れらの行為が第三者の権利を侵害しないことを表明し、保証するものとします。<br>
      ６　二次出品NFTに関して、第三者による訴訟の提起その他権利者が権利を有しないことを理由とした紛争が<br>
      発生した場合、当該二次出品NFT保有者は、その責任と負担においてこれに対応するものとし、当社に一<br>切の負担を生じさせないものとします。また、二次出品NFT保有者は、当社に対し、当該紛争に起因して当<br>社に発生する損害（合理的な弁護士費用を含みます。）を賠償するものとします。<br>
      第９条（二次出品における本<b>NFT</b>の売買の成立及び対価の支払等）<br>
      １　ユーザーは、本サービスを通じて、二次出品NFTを購入することができます（以下、当該ユーザーを「二次<br>
      購入者」といいます。）。<br>
      ２　二次購入者は、購入を希望する二次出品NFTを選択し、当社所定の方法により当社に対して購入の意思<br>
      表示をし、当社所定の方法により当該二次出品NFTの対価及び二次購入者の負担すべき手数料を当社に<br>対して支払った時点で、当社と二次出品NFTの出品者の間及び当社と二次購入者の間にそれぞれ当該二<br>次出品NFTについての売買契約が成立し、本NFT保有者は当社所定の方法により当該本NFTを出品者の<br>本ウォレットから購入者の本ウォレットに送付します。<br>
      ３　当社は、前項に定める当社と二次購入者の間における二次出品NFTの売買契約に基づいて当該二次購<br>
      入者から受領した当該二次出品NFTの対価から、出品者の負担すべき手数料を減じたものを当社所定の<br>方法により当該出品者に支払います。<br>
      ４　当社は、二次出品NFTの購入金額その他当該二次出品NFTの売買に関連する事項について一定の制限<br>
      を設ける場合があり、ユーザーはこれに従うものとします。<br>
      ５　二次購入者は、二次出品NFTについて当社との売買契約が成立した後は、民法、消費者契約法その他の<br>
      法律により認められる場合を除き、当該売買契約の取消しやキャンセル等を行うことはできません。<br>
      第<b>10</b>条（手数料等及びロイヤリティ）<br>
      １　本サービスを利用する際にユーザーが負担すべき手数料は、別途当社が定めるものとします。<br>２　権利者は、自らが一次出品を依頼した本NFTが二次出品され、他のユーザーにより購入された場合に、当<br>
      該二次出品に係る本NFTの購入者から、権利者自身が当社所定の方法及び条件に従い、当該二次出品<br>に係る本NFTの売買金額の一部（以下「ロイヤリティ」といいます。）を受け取ることができます。<br>
      ３　権利者が、当社により本サービスの利用の全部若しくは一部を制限され、又は本サービスの利用登録を削<br>
      除された場合には、前項の二次購入者は、権利者に対し、ロイヤリティを支払う義務を負わないものとしま<br>す。<br>
      第<b>11</b>条（知的財産権等）<br>
      １　本NFTが出品され、購入された場合であっても、当該本NFTが表章するコンテンツやデジタルチケットの知<br>
      4<br>
      <hr>
      <a name="5" />的財産権は権利者に留保され、本NFT保有者には移転しないものとします。<br>
      ２　前項の規定に関わらず、権利者は、本NFT保有者に対し、次の各号に定める行為に必要な範囲で、当該<br>
      本NFTに係る知的財産権を非独占的に利用することを無償で許諾又は再許諾するものとします。<br>
      （１）本NFT保有者が、当該本NFTを本ウォレットで保管する行為<br>（２）本NFT保有者が、当該本NFTを二次出品する行為<br>（３）本NFT保有者が、当該本NFTが表章するコンテンツやデジタルチケットを提供する者が別途定める利<br>
      用規約等において定める方法に従いこれらのコンテンツやデジタルチケットを利用する行為<br>
      ３　権利者は、当社に対し、当社が当該権利者に対して本サービスを提供するために必要な範囲で、本NFT<br>
      に係る知的財産権を非独占的に利用することを無償で許諾するものとします。<br>
      ４　権利者は、当社及び本NFT保有者に対し、前２項に定める許諾又は再許諾の範囲内での使用、利用又は<br>
      実施等に関して、著作者人格権を行使しないものとします。<br>
      ５　本NFT保有者は、その保有する本NFTに関し第２項第３号に定める利用規約等及び第３項によって許諾さ<br>
      れた範囲でのみ、本NFTを利用することができます。<br>
      ６　権利者が発行した本NFTが表章するコンテンツやデジタルチケットに著作権等管理事業者がその著作権<br>
      を管理する著作物が含まれていること等により、その利用に関して当該著作権等管理事業者との間の契約<br>や著作権使用料の支払を要する場合、当該契約及び支払その他一切の手続は、当該権利者の責任と負<br>担において対応するものとし、当社に一切の負担を生じさせないものとします。<br>
      第<b>12</b>条（本<b>NFT</b>が表章するデジタルチケットの扱い）<br>
      当社は、本NFTがデジタルチケットを表章する場合、当該デジタルチケットによって参加することができるイベ<br>ント等が、当該イベント等を提供する者の事情その他本サービスに関係しない事情により中止された場合で<br>あっても当社は当該デジタルチケットの払戻しは行わず、また、本NFT保有者に生じた損害等の一切について<br>責任を負わないものとします。<br>
      第<b>13</b>条（禁止行為）<br>
      １　ユーザーは、本サービスの利用に際して、次の各号に定める行為を行ってはなりません。<br>
      （１）次のいずれかに該当するメッセージ、情報、データ、テキスト、ソフトウェア、画像ファイル又は資料等を<br>
      送信又は掲載する行為<br>
      ①違法、不正又は有害なもの<br>②他者のプライバシー権、知的財産権その他の権利を侵害するもの<br>③脅迫、虐待、嫌がらせ、中傷、名誉毀損、残虐、暴力、下品又はわいせつにあたるもの<br>④虚偽又は詐欺にあたるもの<br>⑤誤解を招く内容のもの<br>⑥差別又は偏見にあたるもの<br>⑦①から⑥までに定める行為の他、公序良俗に反するもの、本サービス若しくはその他のサー<br>
      ビス等の秩序を乱すもの、又は他のユーザーに不快感を与えるもの<br>
      （２）本サービス以外のコミュニケーション機能のあるツール又はアプリへの誘導や参加を促す行為<br>（３）マルウェアを含む素材若しくはファイル等を提示又は送信する行為<br>（４）他のユーザーによる本サービスの利用に悪影響を及ぼすような方法で本サービスを利用する行為<br>（５）広告及び宣伝用の資料、ジャンクメール、スパムメール若しくはチェーンメールを送信する行為、又は<br>
      ネズミ講、連鎖販売取引（マルチ商法）その他の勧誘方法を提示又は送信する行為<br>
      （６）本サービス及びこれに係るコンテンツ等を当社の事前の承諾を得ることなく商業目的で利用する行為、<br>
      募金、カンパ、寄付、布施等の金品の交付を受けること若しくは出資を募ることを目的とする行為、<br>宗教活動、又は宗教団体その他の団体への勧誘行為<br>
      （７）本サービス及びこれに係るコンテンツ等の内容を、無断で複製、転載、改変、翻案、掲示、出版、放送<br>
      又は公演等をする行為<br>
      （８）第三者の個人情報等（住所、電子メールアドレス、電話番号、クレジットカード番号又は口座番号等を<br>
      含みます。）を掲載、公開、収集又は蓄積する行為<br>
      （９）本NFTを本サービス以外のマーケットプレイス、ウォレットその他のプラットフォームに転送する行為<br>（10）本サービス外において、本NFTの譲渡、交換、貸与若しくは再許諾等を行う行為、又はそれに準じた<br>
      行為若しくは発言をする行為<br>
      （11）本サービスのプログラム上の瑕疵その他の問題のある現象若しくは効果を利用して利益を得る等の目<br>
      的又はこれらに準ずる不正な目的に利用する行為<br>
      （12）同音異義語、派生語、綴り間違い又は当て字を含んだ様々な形の禁止事項に抵触するメッセージ、<br>
      情報等を発信する行為<br>
      （13）サーバーへの不正アクセス、不正ツールの利用、チートコード若しくはチートデバイスの配布その他の<br>
      本サービスの運営に支障を与える行為、又はその運営を妨げるおそれのある行為<br>
      （14）当社、当社の関係会社、他のユーザーその他の第三者になりすます、又はなりすまそうとする行為<br>（15）本NFTに関連して、若しくは本NFTに含まれる形で提供されたソフトウェア、プログラム又はデータを複<br>
      製、改変、リバースエンジニアリング、逆コンパイル、逆アセンブル、再現等する行為<br>
      （16）自動操作ツール、プログラム、マクロその他のツールを利用する行為<br>（17）本サービスの運用又は内容に関するプログラムを開発する、開発させる、配布する又は配布させる行<br>
      5<br>
      <hr>
      <a name="6" />為<br>
      （18）本サービスの利用にあたり、虚偽の情報（ユーザー情報を含みます。）を提供する行為<br>（19）架空の名義、又は他人の実名、ブランド名若しくは法人名等、第三者が権利を有する言葉やフレーズ<br>
      を使用する行為<br>
      （20）決済時における不正行為<br>（21）ユーザーID及びパスワードを第三者に貸与、売買、贈与、その他移転等する行為、又は本サービス内<br>
      に別段の定めがあるときを除き、第三者に利用させる行為<br>
      （22）同一人物が複数の利用登録を行う行為又は第三者からユーザーID及びパスワードを譲り受けるなど<br>
      して複数のユーザーとして本サービスを利用する行為<br>
      （23）当社の指定する地域以外の地域から本サービスを利用する行為<br>（24）本マーケットプレイスにおいて、真に売買を行う意思なく、本NFTを出品し又は購入する行為<br>（25）本NFTの二重譲渡に該当する行為又はこれを試みる行為<br>（26）本マーケットプレイスにおける本NFTの価格の変動を図る目的のために行う次に掲げる行為<br>
      1<br>
      行為者が直接経験又は認識していない合理的な根拠のない事実を不特定多数の者に流布<br>すること<br>
      2<br>
      他人を錯誤に陥れるような手段を用いて詐欺的な行為を行うこと。徒に他人の射幸心をあおる<br>ような言動を行うこと<br>
      3<br>
      暴行又は脅迫を用いること<br>
      （27）本NFTの売買に関して、不正の手段、計画若しくは技巧をし、又は重要な事項について虚偽若しくは<br>
      誤解を生じさせる表示をする行為<br>
      （28）本サービス又は本NFTを賭博・ギャンブルに利用する行為<br>（29）公序良俗に反する行為、本サービスの秩序を乱す行為、又は他のユーザーに不快感を与える行為<br>（30）法令、裁判所の判決、決定若しくは命令、又は行政機関の命令等に違反する行為<br>（31）マネー・ローンダリング又はテロ資金供与への関与を行う行為又は当該関与が疑われる行為<br>（32）イランや北朝鮮から本サービスを利用する行為<br>（33）本項各号に定める行為を教唆、助長、幇助する行為<br>（34）前各号に定めるものの他、当社が不適当と判断する行為<br>
      ２　ユーザーは、他のユーザー等による前項各号に定める行為を発見した場合、直ちに別途定める当社への<br>
      問合せ先にその旨を通知するものとします。ただし、当該行為に係る調査、対応、対応に係る公表等は、当<br>社の裁量にて行います。<br>
      ３　当社は、ユーザーにつき第1項各号の行為の疑いがある場合、自己の裁量により、当該ユーザーに対し<br>
      て、本NFTの出品や購入の停止、取消又は無効化その他の暫定的な措置を執る場合があります。このよう<br>な場合において、当社は、かかる措置によりユーザーに生じる損害について、当社に故意又は重大な過失<br>がある場合を除き、責任を負わないものとします。<br>
      第<b>14</b>条（利用制限及び登録抹消）<br>
      １　当社は、ユーザーが次の各号のいずれかに該当する場合には、事前の通知なく、当該ユーザーに対して<br>
      本サービスの全部若しくは一部の利用を制限し、又はユーザーとしての登録を抹消（サービス利用契約の<br>解除）することができます。なお、当社は、これらの措置によりユーザーに生じる損害について、当社に故意<br>又は重大な過失がある場合を除き、責任を負わないものとします。<br>
      （１）本規約のいずれかの条項に違反した場合<br>（２）登録事項に虚偽の事実があることが判明した場合<br>（３）決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合<br>（４）支払停止若しくは支払不能となり、又は、破産手続、民事再生手続その他これらに類する手続の開始<br>
      の申立てがあった場合<br>
      （５）当社からの連絡に対し、1ヶ月以上応答がない場合<br>（６）1か月以上本サービスの利用がない場合<br>（７）第3条第5項各号に該当する場合<br>（８）その他、当社が本サービスの利用を適当でないと判断した場合<br>
      ２　ユーザーが前項各号のいずれかに該当した場合、当該ユーザーは、当社に対する一切の債務について<br>
      当然に期限の利益を失い、その時点において当社に対して負担する一切の債務を直ちに一括して弁済し<br>なければなりません。<br>
      第<b>15</b>条（退会）<br>
      １　ユーザーは、当社ウェブサイト上でアカウント削除の手続を行うことにより、本サービスから退会することがで<br>
      きます。<br>
      ２　当社は、ユーザーが本サービスから退会した場合又は登録を抹消された場合、当該ユーザーのユーザー<br>
      ID及びパスワードに紐づくデータを当社の裁量により削除でき、当該ユーザーはこのことに何らの異議も申<br>し立てられません。<br>
      第<b>16</b>条（本サービスの停止等）<br>
      １　当社は、本サービス及び本NFTについて、いつでも任意にその内容や仕様を変更することができます。<br>
      6<br>
      <hr>
      <a name="7" />２　当社は、以下のいずれかに該当する場合には、ユーザーに対する事前の通知なしに、本サービスの全部<br>
      又は一部の提供を停止又は中断することができます。<br>
      （１）本サービスに係るシステム（サーバー、通信回線や電源、それらを収容する建築物などを含みます。）<br>
      の点検又は保守作業を行う必要がある場合<br>
      （２）コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等によ<br>
      り本サービスの運営に支障が生じた場合<br>
      （３）地震、落雷、火災、風水害、停電、天災地変、戦争、変乱、暴動、騒乱、労働争議等などにより本サー<br>
      ビスの運営に支障が生じた場合<br>
      （４）法令又はこれに基づく措置により本サービスの提供ができなくなった場合<br>（５）その他、当社が停止又は中断を必要と判断した場合<br>
      ３　本条に基づく当社の措置について、ユーザーは異議を申し立てられず、これによってユーザーに損害が生<br>
      じたとしても、当社は当該損害につき責任を負いません。<br>
      第<b>17</b>条（本サービスの内容の変更及び本サービスの終了）<br>
      １　当社は、当社の都合により、いつでも、サービス利用契約を解除し、本サービスの提供を終了することがで<br>
      きます。<br>
      ２　当社が本サービスの提供を終了する場合、当社はユーザーに事前に通知又は公表することとします。<br>
      第<b>18</b>条（免責）<br>
      ユーザーは、本マーケットプレイスの利用にあたり、本規約を以て、次の各号に定める事項について承諾し、ま<br>た同意するものとします。<br>
      （１）当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能、価値、正確性、有用性<br>
      を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令等に適合すること、<br>継続的に利用可能なこと、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証<br>するものではありません。<br>
      （２）当社は、債務不履行、不法行為その他の法律上の請求原因の如何を問わず、本サービスに関連して<br>
      ユーザーが被った損害につき、当該ユーザーが過去（12）か月間]に本サービスに関して当社に対<br>して支払った金額又は10万円のいずれか低い金額を超えて賠償する責任を負わないものとし、ま<br>た、付随的損害、間接損害、特別損害、将来の損害及び逸失利益に係る損害については、賠償す<br>る責任を負いません。ただし、当該損害が当社の故意若しくは重大な過失による債務不履行又は<br>不法行為によって発生した場合は、かかる限定は適用されません。<br>
      （３）当社は、本サービスに関して、ユーザー間及びユーザーと第三者との間において生じた取引、連絡又<br>
      は紛争等について一切責任を負いません。<br>
      （４）当社は、本NFTの売買において、無効、取消、又は解除その他契約の成立又は有効性を妨げる事由<br>
      がないことについて何ら保証いたしません。<br>
      （５）当社は、本NFTの価値、安定性及び適法性につき、いかなる保証もいたしません。<br>（６）当社は、出品者が本規約に違反した出品や、その他当社が不適当と合理的に判断した出品をした場<br>
      合、当該出品者に事前に通知することなく、当該出品を停止、取消又は無効にすることができます。<br>出品が停止又は取消又は無効となった場合に、当社は当該出品に対応する購入をも無効にするこ<br>とができ、これにより出品者又は購入者に損害が発生したとしても、当社はその損害を賠償する責<br>任を負わないものとします。<br>
      （７）当社は、本NFTの売買が成立した場合であっても、システム異常若しくはその他の原因で誤って売買<br>
      が成立したとき、実勢価格と明らかに乖離した価格で売買が成立した場合又は諸般の事情に照らし<br>て不正な売買であると当社が判断した場合においては、これを事後的に取消すことができます。ま<br>た、これにより出品者又は購入者に損害が発生したとしても、当社はその損害を賠償する責任を負<br>わないものとします。<br>
      （８）本NFT及び暗号資産に対する法令等並びに国内外の租税法に基づく義務については、ユーザーの<br>
      責任で確認・履行すべきものであり、これらに起因してユーザーが損害を被ったとしても、当社は一<br>切責任を負いません。<br>
      （９）当社は、ユーザーが本規約に違反した結果として被った損害について、一切責任を負いません。<br>（10）当社は、当社の裁量において、本マーケットプレイスの利用に不適格と判断したユーザーについて、<br>
      その利用の停止等の措置をとることができます。<br>
      第３章　一般条項<br>
      第<b>19</b>条（利用規約の変更）<br>
      １　当社は、以下のいずれかに該当する場合に、法令に定める範囲内で本規約を変更できるものとし<br>
      ます。<br>(１)&#160;本規約の変更が、ユーザーの一般の利益に適合するとき<br>(２)<br>
      本規約の変更が、本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変<br>
      7<br>
      <hr>
      <a name="8" />更の内容その他の変更に係る事情に照らして合理的なものであるとき。<br>
      ２&#160;当社は、前項による本規約の変更内容及び効力発生時期を、当社のウェブサイトに掲載する方法<br>
      その他の適切な方法により周知します。当該変更は、当該周知に際して当社が定める相当な期間を<br>経過した日から適用されるものとします。<br>
      第<b>20</b>条（連絡又は通知）<br>
      １　本サービスに関する問い合わせその他ユーザーから当社に対する連絡又は通知、及び本規約の変更に<br>
      関する通知その他当社からユーザーに対する連絡又は通知は、登録情報であるメールアドレス宛てにメー<br>ルを送信する方法により行います。<br>
      ２　当社が登録事項に含まれるメールアドレスその他の連絡先に連絡又は通知を行った場合、ユーザーは、発<br>
      信時において、当該連絡又は通知を受領したものとみなします。<br>
      第<b>21</b>条（広告及びリンクの掲載）<br>
      ユーザーは、本サービスを利用するにあたって、広告及びリンクされた他のサービス（以下「広告等」といいま<br>す。）が画面上に表示される場合があることを理解しこれを承諾したものとみなします。本サービスで掲載され<br>た広告等の内容につき、当社は一切の責任を負いません。なお、本サービス上の広告等の形態や範囲は、当<br>社によって随時変更されます。<br>
      第<b>22</b>条（契約上の地位の譲渡等）<br>
      １　ユーザーは、当社の書面による事前の承諾なく、サービス利用契約上の地位又はこれらに基づく権利若し<br>
      くは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。<br>
      ２　当社は本サービスに係る事業を他社に譲渡した際には、当該事業譲渡に伴いサービス利用契約上の地位<br>
      又はこれに基づく権利若しくは義務並びにユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受<br>人に譲渡することができるものとし、ユーザーは、当該譲渡につき同意します。なお、本項に定める事業譲<br>渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含みます。<br>
      第<b>23</b>条（効力）<br>
      １　本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効と判断された場合で<br>
      あっても、本規約の残りの規定及び一部が無効と判断された規定の残りの部分は、継続して効力を有しま<br>す。<br>
      ２　本規約の全部又は一部の定めが無効とされうる場合であっても、当該定めはこれが有効とされるように合理<br>
      的かつ限定的に解釈されます。<br>
      第<b>24</b>条（準拠法及び管轄裁判所等）<br>
      １　本規約及びサービス利用契約は、日本法に準拠し、日本法に従って解釈されます。<br>２　本規約は、日本語を正文とします。本規約につき、参考のために英語による翻訳文が作成された場合で<br>
      も、日本語の正文のみが契約としての効力を有するものとし、英訳はいかなる効力も有しないものとします。<br>
      ３　本規約又はサービス利用契約に起因し、又は関連する一切の紛争については、福岡地方裁判所を第一審<br>
      の専属的合意管轄裁判所とします。<br>
      以上<br>
      2023年1月31日制定<br>
      8<br>
    </div>
  </div>
  <div
    v-else
    :class="docMain"
  >
    <head>
      <title>マツモト様_個人情報保護方針</title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <h1 class="doc">
      Terms of Use of NFT Marketplace
    </h1><br>
    These terms of use (these “Terms”) relate to the use of “Shinovi”, an NFT marketplace operated by<br>MATSUMOTO INC. (“we” or “us”) on the Internet, (the “Marketplace”, and together with related services,<br>hereinafter referred to as the “Services”) and set forth matters that Users (as defined in Article 2, Item (1) below) nd prospective Users of the Services shall comply with as well as rights and obligations between Users and us.<br>In order to use the Services, you must read the full text of these Terms and agree to these Terms.<br>
    <b>Chapter I  Fundamentals</b><br>
    <br>
    <b>Article 1 (Definitions)</b><br>
    Terms used herein shall be defined as below:<br>
    (1) “User” means a person who uses the Services;<br>(2) “User ID” means an e-mail address that a User registers in order to use the Services;<br>(3) “Password” means a unique character string set by a User associated with a User ID;<br>(4) “Content” means texts, sounds, music, images, videos, software, programs, codes and other<br>
    information;<br>
    (5) “Digital Ticket” means an electronic voucher issued by us that functions as the right to participate in an<br>
    event held by a Right Holder, and can be used to the Right Holder only for 180 days from the date<br>when it is issued and granted to the User in the form of an NFT;<br>
    (6) “Crypto-asset” means the crypto-assets defined in Article 2, Paragraph 5 of the Payment Services Act;<br>(7) “NFT” means a non-fungible token issued on a blockchain that represents Contents, Digital Tickets,<br>
    etc.;<br>
    (8) “Company Website” means a website that has a domain of “Shinovi.io” and is operated by us (if the<br>
    domain or content of our website changes for any reason, such changed website shall be included);<br>
    (9) “Personal Information” means personal information defined in Article 2, Paragraph 1 of the Act on the<br>
    Protection of Personal Information, that is, (i) information about a living individual which can identify<br>the specific individual by name or other description contained in such information, and (ii) information bout a living individual which contains an individual identification code. For the avoidance of doubt,<br>this term has the same meaning as personal information set forth in the Act on the Protection of<br>Personal Information, and if relevant laws and regulations are amended, the definition thereof shall pply;<br>
    (10) “Registration Information” means information that a User provides to us in registration for the use of<br>
    the Services (including subsequent changes in the information that the User notifies);<br>
    (11) “Offer” means making an NFT available for purchase on the Marketplace in a manner determined by<br>
    us:<br>
    (12) “Seller” means a person who Offers an NFT on the Marketplace;<br>(13) “Right Holder” means a person who has the rights necessary for issuing, Offering and selling NFTs<br>
    representing Intellectual Property Rights (as defined in item 4) over Contents or Digital Tickets;<br>
    (14) “NFT Holder” means a User who has purchased an NFT on the Marketplace and currently owns the<br>
    NFT on the Services;<br>
    (15) “Intellectual Property” means inventions, devices, designs, works and other property that is created<br>
    through creative activities by human beings; trademarks, tradenames and other property that<br>represents goods or services used in business activities; and trade secrets and other technical or usiness information that is useful for business activities;<br>
    (16) “Intellectual Property Right” means a patent right, utility model right, design right, copyright,<br>
    trademark right, and any other right provided by laws and regulations in relation to Intellectual<br>Property (including the right to acquire such rights or to apply for registration of such rights) or any<br>right over a legally protected interest;<br>
    (17) “Antisocial Forces” mean any person who falls under any of the following items:<br>
    (i) An organized crime group (which refers to an organized crime group defined in Article 2, Item<br>
    (ii) of the Act on Prevention of Unjust Acts by Organized Crime Group Members (Act No.77 of<br>1991; hereinafter referred to as “Anti-Organized Crime Group Act”));<br>
    (ii) An organized crime group member (which refers to an organized crime group member defined<br>
    in Article 2, Item (vi) of the Anti-Organized Crime Group Act);<br>
    (iii) A person who was an organized crime group member in the past 5 years;<br>
    <hr>
    <a name="2" />(iv) A quasi-member of an organized crime group; (v) A company associated with an organized crime group;<br>(vi) A corporate racketeer, etc. (which refers to a persons who is likely to commit violent illegal
    acts against a company or other entities in the pursuit of unjust gains and threatens the safety of civil life including a corporate racketeer (<i>sokaiya</i>) and corporate extortionist (<i>kaisha goro</i>));
    (vii) A rogue person or group proclaiming itself as a social or political activist, etc. (which refers<br>
    to a person who is likely to commit violent illegal acts in the pursuit of unjust gains on the pretext of a social or political campaign and threatens the safety of civil life);<br>
    (viii) A special intellectual crime group (which refers to a group or an individual who is not listed<br>
    in (i) through (vii) above, and uses the force of an organized crime group or has a financial relationship with an organized crime group to play a central role in an organized fraud);<br>
    (ix) Any other person who is equivalent to those listed in (i) through (viii) above;<br>(x) A person who has a relationship in which its management is deemed to be controlled by a<br>
    person listed in (i) through (ix) above (“Organized Crime Group Member, Etc.”);<br>
    (xi) A person who has a relationship in which an Organized Crime Group Member, Etc. is deemed<br>
    to be substantially involved in its management;<br>
    (xii) A person who has a relationship in which it is deemed to unjustly use an Organized Crime<br>
    Group Member, Etc. for the purpose of making improper gains for itself, its company or a third<br>party, or for the purpose of damaging a third party;<br>
    (xiii) A person who has a relationship in which it is deemed to be involved and willingly cooperate<br>
    in the maintenance and operation of an Organized Crime Group Member, Etc. through<br>providing funds or benefits to the Organized Crime Group Member, Etc.;<br>
    (xiv) A person whose offer or other persons substantially involved in its management have a<br>
    relationship with an Organized Crime Group Member, Etc. that should be socially criticized.<br>
    <br>
    <b>Article 2 (Scope of Application)</b><br>
    1. These Terms set forth the terms and conditions for the provision of the Services, and the rights and<br>
    obligations between Users and us in connection with the use of the Services, and shall apply to all<br>relationships between Users and us in connection with the use of the Services.<br>
    2. Rules on the use of the Services, policies and others (“Use Rules”) that we publish on the Company<br>
    Website shall constitute a part of these Terms.<br>
    3. In case of any inconsistency or discrepancy between these Terms and the Use Rules in the preceding<br>
    paragraph, the provisions of these Terms shall prevail unless otherwise specifically provided for in such<br>Use Rules.<br>
    <br>
    <b>Chapter II  Use of the Marketplace</b><br>
    <br>
    <b>Article 3 (Registration for Use)</b><br>
    1. Those who desire to use the Services are required to read the full text of these Terms and agree to comply<br>
    with these Terms beforehand. In addition, we may request them to apply for registration for the use of the<br>Services by providing us with information concerning matters specified by us (the “Registration Matter”) in  manner specified by us.<br>
    2. We shall judge the propriety of registration of the person who applied for the registration in accordance<br>
    with the preceding paragraph (the “Registration Applicant”) and notify the Registration Applicant of the<br>result.<br>
    3. When we notify the Registration Applicant of acceptance of the registration pursuant to the preceding<br>
    paragraph, an agreement on the use of the Services (the “Service Use Agreement”) consistent with the<br>terms and conditions of these Terms will be formed between the Registration Applicant and us, and the<br>Registration Applicant will be allowed to use the Services as a User in accordance with these Terms.<br>
    4. A Registration Applicant who is a person with limited capacity such as a minor must obtain the consent of<br>
    his/her statutory agent in advance of agreeing to these Terms. If a Registration Applicant is a minor, we<br>may contact the Registration Applicant or his/her statutory agent to confirm whether the consent of the<br>statutory agent has been obtained.<br>
    5. We may refuse registration for use under Paragraph 2 of this article if the relevant Registration Applicant<br>
    falls under any of the following items. We are not obliged in any way to disclose the reason for such refusal<br>of the registration:<br>
    (1) All or some of the Registration Matters that the Registration Applicant provided to us are false,<br>
    incorrect or incomplete;<br>
    (2) The Registration Applicant is a minor, an adult ward, a person under curatorship, or a person under<br>
    assistance and has not obtained the consent of his/her statutory agent, guardian, curator, or assistant;<br>
    2<br>
    <hr>
    <a name="3" />(3) We determine that the Registration Applicant is Antisocial Forces;<br>(4) We determine that the Registration Applicant is a person who has breached a contract with us in the<br>
    past or is an affiliate of such person;<br>
    (5) The Registration Applicant is found to be a person whose registration as a User was deleted by us in<br>
    the past;<br>
    (6) We determine that the Registration Applicant is the same person as a person who has already<br>
    completed the registration for use;<br>
    (7) It is reasonably believed that the Registration Applicant is likely to use the Services for criminal acts<br>
    such as money laundering and fraud;<br>
    (8) It is reasonably believed that the Registration Applicant is likely to use the Services in a manner that<br>
    violates laws and regulations or these Terms; or<br>
    (9) Otherwise we determine that it is inappropriate to register the Registration Applicant.<br>
    6. When a User who was a minor at the time of an application for registration uses the Services after reaching<br>
    the age of majority, such User shall be deemed to have ratified any and all judicial acts relating to the<br>Services.<br>
    7. Each Registration Applicant undertakes that he/she does not, and will not in the future, fall under any of the<br>
    Antisocial Forces, and that he/she will not, either by itself or by using a third party, make violent demands,<br>make unreasonable demands that go beyond the legal liability, resort to intimidating behaviors or violence<br>in relation to transactions, damage our reputation or interfere with our business by spreading false rumors<br>or by using fraudulent means or force, nor commit any other equivalent acts. If we determine that a User<br>falls under any of the Antisocial Forces or commits an act that breaches the foregoing undertaking, we are<br>entitled to suspend the provision of the Services without prior notice to the User and we are not liable for ny damage or disadvantage incurred by such User as a result thereof.<br>
    8. When information pertaining to the Registration Matters changes, the User shall immediately change the<br>
    Registration Information and it is the responsibility of the User to manage and correct the Registration<br>Information so that the Registration Information always reflects accurate information of the User. If the<br>Registration Information is not corrected despite a change in information pertaining to the Registration<br>Matters, we may treat the Registration Information as unchanged. Even if a notification of change is<br>submitted, we may rely on the pre-change information in relation to transactions and procedures undertaken<br>prior to the registration of the change.<br>
    9. We shall handle the Registration Information in accordance with the Personal Information Protection Policy<br>
    (https://www.matsumoto-inc.co.jp/privacypolicy.html) separately established by us, and Users hereby<br>consent to our handling of their user information in accordance with the Personal Information Protection<br>Policy.<br>
    10. We may use and disclose information, data, etc. provided by Users, as statistical information that is unable<br>
    to identify any individual.<br>
    <br>
    <b>Article 4 (Management of Password and User ID)</b><br>
    1. Users shall properly manage and keep their Passwords and User IDs pertaining to the Services at their own<br>
    responsibility, and shall not allow a third party to use them, nor lend, assign, transfer or sell them to a third<br>party.<br>
    2. Users shall be fully liable for any damage caused by insufficient management, misuse, or use by a third<br>
    party of their Passwords or User IDs.<br>
    3. If any Registration Information is found to be stolen or used by a third party, the relevant User shall<br>
    immediately notify us thereof and follow our instructions. In such case, we may suspend the use of the<br>Services by such User ID and Password, and we shall not be liable for any damage incurred by such User s a result of such suspension of use.<br>
    4. We may treat any use of the Services as the use by the User of such account, and any and all consequences<br>
    arising from such use and any and all responsibility to us in connection therewith shall be attributed to such<br>User.<br>
    <br>
    <b>Article 5 (Management of Wallet)</b><br>
    In order to use the Services, Users shall use, in accordance with the manner separately specified by us, a<br>self-custody wallet (the “Wallet”) provided by us or a third party other than us for which Users shall manage<br>the seed phrase and private key to transfer NFTs (the “Private Key, Etc.”) by themselves. The Wallet is for<br>Users to manage by themselves the Private Key, Etc. to transfer NFTs, and Users shall manage the Private<br>Key, Etc. at their own responsibility when using the Wallet, and we shall not be liable for any damages<br>incurred by Users due to leakage, loss of or damage to such Private Key, Etc.<br>
    <br>
    <b>Article 6 (Initial Offer of NFT)</b><br>
    1. Right Holders assign to or approve for us any and all rights required to Offer NFTs and request us to issue<br>
    and Offer the NFTs on the Marketplace through the Services (“Initial Offer”). No person other than the<br>
    3<br>
    <hr>
    <a name="4" />Right Holders has the right to request us to make an Initial Offer of an NFT on the Marketplace.<br>
    2. When requesting us to make an Initial Offer of an NFT pursuant to the preceding paragraph, the Right<br>
    Holder, in accordance with the sales method separately specified by us, (i) determines the Offering price of<br>the NFT, or (2) entrusts us with the determination of the Offering price.<br>
    3. We will make an Initial Offer of the NFT under our name with the Offering price determined pursuant to (i)<br>
    or (ii) of the preceding paragraph. We have the right to set a minimum Offering price for each type of NFT.<br>In such case, Right Holders must determine the Offering price of NFTs at a price not less than the pplicable minimum Offering price.<br>
    4. When a Right Holder requests us to make an Initial Offer of an NFT, the Right Holder may: (i) to the extent<br>
    deemed reasonable by us, decide on the details of the rights granted to the holder of the NFT (including a<br>license to use digital data), the waiting period for the Reoffer (as defined in Article 8: the same shall apply<br>hereinafter) of the NFT, and other rules on the Reoffer and matters concerning Royalty (as defined in<br>Article 10: the same shall apply hereinafter) (the “Offering Conditions”); or (ii) entrust us with the decision<br>on the Offering Condition. In making an Initial Offer under the preceding paragraph, we shall impose the<br>Offering Conditions decided pursuant to (i) or (ii) of this paragraph.<br>
    5. We shall not be liable in any way for any damage incurred by the User as a result of the decision on the<br>
    Offering price or Offering Conditions of the NFT made by us under entrustment under Paragraph 2 and the<br>preceding paragraph.<br>
    6. Right Holders may not request us to Offer the following NFTs:<br>
    (1) NFTs that violate laws and regulations or infringe on a right of a third party or that were obtained<br>
    through an act that violates laws and regulations or through infringement on a right of a third party;<br>
    (2) NFTs whose possession or transaction violates laws and regulations or infringes on a right of a third<br>
    party;<br>
    (3) NFTs whose issuance or transaction violates these Terms; or<br>(4) Other NFTs that we determine inappropriate.<br>
    7. Each Right Holder shall represent and warrant that he/she has all powers necessary for requesting us to<br>
    make an Initial Offer of an NFT through the Services and that such acts do not infringe any right of any<br>third party.<br>
    8. If a lawsuit is filed by a third party or any other dispute arises on the ground that the Right Holder does not<br>
    have rights with respect to an NFT which was made available for purchase under the Initial Offer pursuant<br>to Paragraph 3, the Right Holder shall deal with such dispute at its own responsibility and expense, and<br>shall not cause any burden to us. The Right Holder shall also compensate us for any damage incurred by us s a result of such dispute (including reasonable attorney’s fees).<br>
    <br>
    <b>Article 7 (Conclusion of Sale and Purchase of NFT under Initial Offer and Payment of Price, Etc.)</b><br>
    1. Users may purchase NFTs under the Initial Offer through the Services (such User is hereinafter referred to<br>
    as a “Purchaser”).<br>
    2. At the time when the Purchaser pays the price of the NFT and fees in the manner specified by us on the<br>
    sales page of the NFT under the Initial Offer on the Company Website, a sale and purchase agreement for<br>such NFT shall be formed between such Purchaser and us, and such NFT shall be sent to the Purchaser’s<br>Wallet by the means specified by us.<br>
    3. We may impose certain restrictions on the purchase price, number of times such NFT may be purchased,<br>
    and other matters related to the sale and purchase of such NFT, and Users shall comply with such<br>restrictions.<br>
    4. After the sale and purchase agreement for such NFT is formed, the Purchaser may not rescind or cancel the<br>
    sale and purchase agreement unless permitted by the Civil Code, the Consumer Contract Act or other laws.<br>
    <br>
    <b>Article 8 (Reoffer of NFT)</b><br>
    1. An NFT Holder may re-Offer through the Marketplace the NFT purchased from us or another Seller on the<br>
    Marketplace (“Reoffer” and the Reoffered NFT is referred to as a “Reoffered NFT” in this and the<br>following articles). In the case where the Right Holder has set a waiting period for the Reoffer pursuant to<br>Article 6, Paragraph 4, the NFT Holder may not make a Reoffer during the waiting period.<br>
    2. When Reoffering an NFT pursuant to the preceding paragraph, the NFT Holder determines the Offering<br>
    price of the NFT in accordance with the sales method separately specified by us. We have the right to set a<br>minimum Offering price for each type of NFT. In such case, the NFT Holder must determine the Offering<br>price of the NFT at a price not less than the applicable minimum Offering price.<br>
    3. The NFT Holder may withdraw a Reoffer of a Reoffered NFT in the manner specified by us only before the<br>
    conclusion of a sale and purchase agreement for the Reoffered NFT between the NFT Holder and us as set<br>forth in Paragraph 2 of the following article.<br>
    4. NFT Holders may not Offer the following NFTs:<br>
    (1) NFTs that violate laws and regulations or infringe on a right of a third party or that were obtained<br>
    through an act that violates laws and regulations or through infringement on a right of a third party;<br>
    4<br>
    <hr>
    <a name="5" />(2) NFTs whose possession or transaction violates laws and regulations or infringes on a right of a third<br>
    party;<br>
    (3) NFTs whose issuance or transaction violates these Terms; or<br>(4) Other NFTs that we determine inappropriate.<br>
    5. Each NFT Holder shall represent and warrant that he/she has all rights necessary for Reoffering an NFT<br>
    through the Services and that such acts do not infringe on any right of any third party.<br>
    6. If a lawsuit is filed by a third party or any other dispute arises on the ground that the Right Holder does not<br>
    have rights with respect to a Reoffered NFT, the holder of the relevant Reoffered NFT shall deal with such<br>dispute at its own responsibility and expense, and shall not cause any burden to us. The holder of the<br>relevant Reoffered NFT shall also compensate us for any damage incurred by us as a result of such dispute<br>(including reasonable attorney’s fees).<br>
    <br>
    <b>Article 9 (Conclusion of Sale and Purchase of NFT under Reoffer and Payment of Price, Etc.)</b><br>
    1. Users may purchase Reoffered NFTs through the Services (such User is hereinafter referred to as a<br>
    “Subsequent Purchaser”).<br>
    2. At the time when a Subsequent Purchaser selects a Reoffered NFT that it desires to purchase, expresses its<br>
    purchase intention to us in the manner specified by us and pays to us the price of the Reoffered NFT and<br>fees payable by the Subsequent Purchaser, sale and purchase agreements for the Reoffered NFT are formed etween the Seller of the Reoffered NFT and us, and between the Subsequent Purchaser and us,<br>respectively. The NFT Holder shall send the NFT to the Wallet of the Purchaser from the Wallet of the<br>Seller in the manner specified by us.<br>
    3. We pay the Seller in the manner specified by us the price of the Reoffered NFT received from the<br>
    Subsequent Purchaser based on the sale and purchase agreement for the Reoffered NFT between the<br>Subsequent Purchaser and us under the preceding paragraph less the fees payable by the Seller.<br>
    4. We may impose certain restrictions on the purchase price of the Reoffered NFT and other matters related to<br>
    the sale and purchase of such Reoffered NFT, and Users shall comply with such restrictions.<br>
    5. After the sale and purchase agreement with us is formed for the Reoffered NFT, the Subsequent Purchaser<br>
    may not rescind or cancel the sale and purchase agreement unless permitted by the Civil Code, the<br>Consumer Contract Act or other laws.<br>
    <br>
    <b>Article 10 (Fees and Royalty)</b><br>
    1. We separately determine the fees payable by Users for using the Services.<br>2. If an NFT with respect to which its Right Holder requested us to make the Initial Offer is made available<br>
    for purchase under a Reoffer and purchased by another User, the Right Holder has the right to receive from<br>the Purchaser of the Reoffered NFT a certain portion of the purchase price of the Reoffered NFT<br>(“Royalty”) in accordance with the manner and conditions specified by us.<br>
    3. In the event that the Right Holder is restricted entirely or partially from using the Service or his/her<br>
    registration for use of the Service is deleted by us, the Subsequent Purchaser under the preceding paragraph<br>shall have no obligation to pay Royalty to the Right Holder.<br>
    <br>
    <b>Article 11 (Intellectual Property Rights, Etc.)</b><br>
    1. Even if the NFT is Offered and purchased, the Intellectual Property Rights of the Content and Digital<br>
    Ticket represented by such NFT shall be reserved by the Right Holder and shall not be transferred to the<br>NFT Holder.<br>
    2. Notwithstanding the provision of the preceding paragraph, the Right Holder shall license or sublicense the<br>
    NFT Holder to use the Intellectual Property Rights pertaining to such NFT on a non-exclusive basis<br>without any charge, to the extent necessary for any of the following acts:<br>
    (1) the act that the NFT Holder stores such NFT in the Wallet;<br>(2) the act that the NFT Holder Reoffers such NFT; or<br>(3) the act that the NFT Holder uses the Content and Digital Ticket represented by such NFT in a manner<br>
    prescribed in the terms of use, etc. separately specified by those who provide such Content and Digital<br>Ticket.<br>
    3. The Right Holder shall license our company to use the Intellectual Property Rights pertaining to the NFT<br>
    on a non-exclusive basis without any charge, to the extent necessary for us to provide such Right Holder<br>with the Services.<br>
    4. The Right Holder shall not exercise the moral rights against us and the NFT Holder in connection with the<br>
    use, application or implementation, etc. within the scope of the license or sublicense as set forth in the<br>preceding two (2) paragraphs.<br>
    5. The NFT Holder may use the NFT only to the extent permitted by the terms of use, etc. set forth in<br>
    Paragraph 2, Item 3 and by Paragraph 3 with respect to the NFT held by such NFT Holder.<br>
    6. If, due to the fact that the Content or Digital Ticket represented by the NFT issued by the Right Holder<br>
    contains works whose copyright is managed by the copyrights, etc. collection organization, an agreement<br>
    5<br>
    <hr>
    <a name="6" />with such copyrights, etc. collection organization or the payment of royalties is required for the use thereof,<br>such agreement, payment or any other procedures shall be handled at such Right Holder’s responsibility nd expense and shall not impose any burden on us.<br>
    <br>
    <b>Article 12 (Treatment of Digital Ticket Represented by NFT)</b><br>
    If the NFT represents the Digital Ticket, even if an event, etc. in which people can participate with such<br>Digital Ticket is cancelled due to a circumstance of the provider of such event, etc. or other reasons unrelated<br>to the Services, we shall not refund such Digital Ticket nor be liable for any damage, etc. incurred by the NFT<br>Holder.<br>
    <br>
    <b>Article 13 (Prohibited Acts)</b><br>
    1. The User shall not engage in any of the following acts in using the Services:<br>
    (1) the act of sending or posting messages, information, data, text, software, image files or documents, etc.<br>
    that<br>
    (i) are illegal, fraudulent or harmful;<br>(ii) infringe others’ right to privacy, Intellectual Property Rights or other rights;<br>(iii) constitute threat, abuse, harassment, slander, defamation, cruelty, violence, indecency or<br>
    obscenity;<br>
    (iv) constitute falsity or fraud;<br>(v) contain misleading contents;<br>(vi) constitute discrimination or prejudice; or<br>(vii) offend against public policy, disturb the order of the Services or any other services, etc., or<br>
    cause discomfort to other Users, as well as the acts as set forth in (i) through (vi) above;<br>
    (2) the act of inducing or encouraging participation in tools or applications having communication function<br>
    other than the Services;<br>
    (3) the act of presenting or sending material or files, etc. containing malware;<br>(4) the act of using the Services in a manner that adversely affects other Users’ use of the Services;<br>(5) the act of sending advertising and promotional materials, junk mail, spam mail or chain mail, or the act<br>
    of presenting or sending a pyramid scheme, multilevel marketing or other solicitation methods;<br>
    (6) the act of using the Services and the Content, etc. pertaining thereto for commercial purposes without<br>
    obtaining prior consent of us, the act conducted for the purpose of receiving money and goods or<br>soliciting an investment such as fund-raising, donations, contributions, offerings, etc., religious ctivity, or the act of soliciting participation in religious bodies or other organizations;<br>
    (7) the act of reproducing, reprinting, altering, adapting, posting, publishing, broadcasting or performing<br>
    the contents of the Services and the Content pertaining thereto, etc. without permission;<br>
    (8) the act of posting, disclosing, collecting or storing the Personal Information, etc. of any third party<br>
    (including address, e-mail address, phone number, credit card number or account number);<br>
    (9) the act of transferring the NFT to marketplace, wallet or any other platform other than the Services;<br>(10) the act of assigning, exchanging, lending or sublicensing, etc. the NFT outside the Services, or any<br>
    other similar act or statement;<br>
    (11) the act of using the Services for the purpose of gaining profits by using defect or any other<br>
    problematic phenomenon or effect in the program of the Services, or for any other similar nefarious<br>purposes;<br>
    (12) the act of sending any message, information, etc. that falls foul of various forms of prohibitions,<br>
    including homonyms, derivatives, misspellings, or phonetic equivalents;<br>
    (13) unauthorized access to the server, use of unauthorized tools, distribution of cheat code or cheat device,<br>
    or any other act that interferes with or may disturb the operation of Services;<br>
    (14) the act of impersonating or attempting to impersonate our company, any affiliate of us, another User,<br>
    or any other third party;<br>
    (15) the act of reproducing, altering, reverse-engineering, decompiling, disassembling or recreating any<br>
    software, program or data provided in connection with or contained in the NFT;<br>
    (16) the act of using automatic operation tool, program, macro or any other tool;<br>(17) the act of developing, causing to be developed, distributing, or causing to be distributed any program<br>
    relating to the operation or contents of the Services;<br>
    (18) the act of providing false information (including User information) in connection with using the<br>
    Services;<br>
    (19) the act of using a fictitious name, or words or phrases to which a third party holds rights such as<br>
    other’s real name, brand name or company name;<br>
    (20) the fraudulent act at the time of settlement;<br>(21) the act of lending, trading, gifting or otherwise transferring User ID and Password to any third party,<br>
    or, unless otherwise specified in the Services, the act of causing any third party to use User ID and<br>Password;<br>
    6<br>
    <hr>
    <a name="7" />(22) the act of engaging in multiple registrations for use by one person, or the act of using the Services as<br>
    multiple Users by obtaining User ID and Password from any third party;<br>
    (23) the act of using the Services from areas other than those designated by us;<br>(24) the act of Offering or purchasing the NFT in the Marketplace without an intention of truly trading it;<br>(25) the act that constitutes or attempts an overlapped transfer of the NFT;<br>(26) the following acts conducted for the purpose of fluctuating the price of the NFT in the Marketplace;<br>
    (i) disseminating to many and unspecified people the facts which the conductor has not directly<br>
    experienced or recognized and has no reasonable grounds;<br>
    (ii) engaging in a fraudulent act by using means which may bring others into a mistake, or<br>
    engaging in any behavior that may unnecessarily encourage the gambling spirit of others; or<br>
    (iii) using violence or threat;<br>
    (27) the act of using fraudulent means, plans or techniques, or making false or misleading representations<br>
    with respect to material matters, in connection with the sale and purchase of the NFT;<br>
    (28) the act of using the Services or the NFT for gaming or gambling;<br>(29) the act that offends against public policy, disturbs the order of the Services or causes discomfort to<br>
    other Users;<br>
    (30) the act in violation of laws and regulations, judgments, decisions or orders of courts, or orders, etc. of<br>
    administrative agencies;<br>
    (31) the act of engaging in or being suspected of engaging in money laundering or terrorist financing;<br>(32) the act of using the Services from Iran or North Korea;<br>(33) the act that induces, facilitates or abets any of the acts set forth in the items of this paragraph; or<br>(34) the act that we deem inappropriate, as well as the acts set forth in the preceding items.<br>
    2. If the User finds any of the acts set forth in the items of the preceding paragraph committed by another<br>
    User, the User shall immediately notify the contact address of our company as separately specified to that<br>effect, provided, however, that any investigation, handling of such act or public announcement of such<br>handling shall be made at the discretion of us.<br>
    3. If any of the acts in the items of Paragraph 1 is suspected to exist on the part of the User, we may, at our<br>
    own discretion, take provisional measures against such User including suspension, cancellation or<br>invalidation of Offer or purchase of the NFT. In such case, we shall not be liable for any damage incurred y the User as a result of such measure unless there is willful misconduct or gross negligence on the part of<br>us.<br>
    <br>
    <b>Article 14 (Limitation of Use and Deregistration)</b><br>
    1. If any of the following items applies to the User, we may restrict such User’s use of the Services in whole<br>
    or in part or delete their registration as a User (cancellation of the Service Use Agreement) without prior<br>notice. We shall not be liable for any damage incurred by the User as a result of such measures unless there<br>is willful misconduct or gross negligence on the part of us:<br>
    (1) cases where the User violates any of the provisions in these Terms;<br>(2) cases where a false representation of fact is found in the Registration Matter;<br>(3) cases where a credit card registered by the User as a means of settlement is suspended;<br>(4) cases where the User suspends payment or becomes insolvent, or a petition for commencement of<br>
    bankruptcy proceedings, civil rehabilitation proceedings or any other similar proceedings is filed gainst the User;<br>
    (5) cases where the User does not respond to contact from us for one (1) month or more;<br>(6) cases where the User does not use the Services for one (1) month or more;<br>(7) cases where any of the items of Article 3, Paragraph 5 applies to the User; or<br>(8) any other cases where we deem the use of the Services by the User inappropriate.<br>
    2. If any of the items of the preceding paragraph applies to the User, any and all obligations owed to us shall<br>
    automatically become due and payable, and the User shall immediately pay all obligations owed to us at<br>that time in a lump sum.<br>
    <br>
    <b>Article 15 (Withdrawal)</b><br>
    1. The User may withdraw from the Services by completing the account deletion procedures on the Company<br>
    Website.<br>
    2. If the User withdraws from the Services or is deregistered, we may, at our own discretion, delete data<br>
    associated with such User’s User ID and Password, and such User shall not make any objection thereto.<br>
    <br>
    <b>Article 16 (Discontinuance of Services, Etc.)</b><br>
    1. We may change the contents and specifications of the Services and the NFT at any time and at our own<br>
    discretion.<br>
    2. In any of the following cases, we may suspend or discontinue the provision of the Services in whole or in<br>
    part without prior notice to the User:<br>
    7<br>
    <hr>
    <a name="8" />(1) cases where it is necessary to inspect or maintain the system pertaining to the Services (including<br>
    servers, communication lines and power sources, and buildings that house them);<br>
    (2) cases where the operation of the Services is hindered due to failure of computer, communication lines,<br>
    etc., operational error, excessive concentration of access, unauthorized access, hacking, etc.;<br>
    (3) cases where the operation of the Services is hindered due to earthquake, lightning strike, fire, wind and<br>
    flood damage, power failure, act of God, war, disturbance, riot, civil commotion, labor dispute, etc.;<br>
    (4) cases where it becomes impossible to provide the Services under laws and regulations or measures<br>
    based thereon; or<br>
    (5) any other cases where we deem suspension or discontinuance necessary.<br>
    3. The User shall not make any objection to our measures under this Article, and we shall not be liable for any<br>
    damage incurred by the User as a result such measures.<br>
    <br>
    <b>Article 17 (Change of Contents of Services and Termination of Services)</b><br>
    1. We may terminate the Service Use Agreement and the provision of the Services at any time at our<br>
    convenience.<br>
    2. If we terminate the provision of the Services, we shall notify the User or make a public announcement in<br>
    advance.<br>
    <br>
    <b>Article 18 (Disclaimer)</b><br>
    In using the Marketplace, the User shall hereby accept and agree to the following items.<br>
    (1) We shall make no warranty, express or implied, that the Services fit for the User’s particular purpose, or<br>
    have the expected function, value, accuracy, or availability, that the User’s use of the Services complies<br>with laws and regulations applicable to such User, that the Services are continuously available, or that<br>the Services are free from defects.<br>
    (2) We shall assume no responsibility for any damage incurred by the User in connection with the Services,<br>
    whether default, tort or any other legal cause, in excess of the amount paid by such User to us for the<br>Services in the past twelve (12) months or 100,000 yen, whichever is less, and we shall assume no<br>responsibility for incidental damage, consequential loss, special damage, future damage and damage ssociated with lost profits, provided, however, that such limitation shall not apply if such damage is<br>caused by default or tort due to willful misconduct or gross negligence on the part of our company.<br>
    (3) We shall assume no responsibility for any transactions, communications or disputes between the Users<br>
    and between the User and a third party with respect to the Services.<br>
    (4) We shall make no warranty that there is no cause which may prevent formation or validity of a contract<br>
    such as invalidity, cancellation or termination in selling and purchasing the NFT.<br>
    (5) We shall make no warranty for value, stability and legality of the NFT.<br>(6) We may suspend, cancel or invalidate any Offer that violates these Terms or that we reasonably deem<br>
    inappropriate without prior notice to the Seller, and if the Offer is suspended, cancelled or invalidated,<br>we may also invalidate the purchase corresponding to such Offer and we shall not be liable for any<br>damage incurred by the Seller or the Purchaser as a result thereof.<br>
    (7) Even if the transaction of the NFT is effected, we may cancel such transaction after the fact if it is<br>
    effected in error due to a system malfunction or any other cause, if it is effected at a price which<br>obviously deviates from the market price, or if we deem it fraudulent in light of the various factors, and<br>we shall not be liable for any damage incurred by the Seller or the Purchaser as a result thereof.<br>
    (8) The obligations under laws and regulations and domestic and foreign tax laws regarding the NFT and<br>
    the Crypto-asset shall be confirmed and complied at the responsibility of the User, and we shall not be<br>liable for any damage incurred by the User arising therefrom.<br>
    (9) We shall not be liable for any damage incurred by the User as a result of their violation of these Terms.<br>(10) We may, at our own discretion, take measures such as suspending the use of the Marketplace by the<br>
    User if such User is deemed ineligible to use it.<br>
    <br>
    <b>Chapter III  General Provisions</b><br>
    <br>
    <b>Article 19 (Changes to Terms of Use)</b><br>
    1. In any of the following cases, we may change these Terms to the extent permitted by laws and regulations:<br>
    (1) cases where a change to these Terms suits the general benefit of the Users; or (2) cases where a change to these Terms is not inconsistent with the purpose of these Terms and is<br>
    reasonable in light of the necessity of the change, the appropriateness of the changed contents, the<br>details of the change and other factors associated with the change.<br>
    2. We shall announce the details of the change to these Terms and the effective date thereof under the<br>
    preceding paragraph by posting them on the Company Website or other appropriate means. Such change<br>
    8<br>
    <hr>
    <a name="9" />shall be applied from the date when a reasonable period specified by us upon such announcement elapses.<br>
    <br>
    <b>Article 20 (Communications or Notices)</b><br>
    1. Inquiries concerning the Services and other communications or notices from the User to our company, and<br>
    notices concerning changes to these Terms and other communications or notices from our company to the user shall be made by sending emails to the email address which is registered as the Registration Information.<br>
    2. If we send a communication or notice to an email address or other contact addresses included in the<br>
    Registration Matter, the User shall be deemed to have received such communication or notice at the time of sending.<br>
    <br>
    <b>Article 21 (Placement of Advertisements and Links)</b><br>
    In using the Services, the User shall be deemed to have understood and accepted that advertisements and other linked services (the “Advertisements”) may be displayed on the screen. We assume no responsibility for the contents of the Advertisements placed on the Services. The form and scope of the Advertisements on the Services may be changed by us from time to time.<br>
    <br>
    <b>Article 22 (Transfer of Contractual Status, Etc.)</b><br>
    1. The User may not assign, transfer, pledge or otherwise dispose of their status under the Service Use<br>
    Agreement or rights or obligations thereunder to any third party without prior written consent of our<br>company.<br>
    2. When we transfer the business pertaining to the Services to another company, we may transfer our status<br>
    under the Service Use Agreement or rights or obligations thereunder, and the User’s Registration Information and other customer information to the transferee of such business transfer, and the User shall gree to such transfer. The business transfer set forth in this paragraph shall include not only a normal usiness transfer but also a company split or any other cases where the business is transferred.<br>
    <br>
    <b>Article 23 (Validity)</b><br>
    1. Even if any provision of these Terms or part thereof is deemed to be invalid under the Consumer Contract<br>
    Act or other laws and regulations, the remaining provisions of these Terms and the remaining part of the<br>provision a part of which is deemed to be invalid shall continue to have effect.<br>
    2. Even if provisions of these Terms, in whole or in part, may be deemed to be invalid, such provisions shall<br>
    be interpreted in a reasonable and limited manner so as to make them valid.<br>
    <br>
    <b>Article 24 (Governing Law and Jurisdiction, Etc.)</b><br>
    1. These Terms and the Service Use Agreement shall be governed by and construed under the laws of Japan.<br>2. The official text of these Terms shall be in Japanese. Even if an English translation of these Terms is<br>
    prepared for reference purposes, only the official Japanese text shall have the effect as an agreement and<br>the English translation shall have no effect.<br>
    3. Any and all disputes arising out of or in connection with these Terms or the Service Use Agreement shall<br>
    be subject to the exclusive jurisdiction of the Fukuoka District Court in the first instance.<br>
    End<br>
    Established on January 31, 2023<br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <hr>
  </div>
</template>
<script>

import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Terms",
    "components": {
    },
    "props": {
    },
    setup () {

        const serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  margin-top: 100px;
  margin-bottom: 100px;
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>

