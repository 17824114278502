<!--
 The Entity Component creates regular Threejs Objects with userData metadata that makes it compliant in usagami engine
 For example the entity can emit a request to add rigid body physics into the node graph.
-->

<template>
    <div ref="rootNode" />
    <FurryMesh ref="furryMesh"
        :params="fuzzyParams" />
</template>

<script>
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { emits, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import * as THREE from 'three';
import FurryMesh from './FurryMesh.vue';

export default {
  name: 'Entity',
  "components": {
    FurryMesh
  },
  "props": {
  },
  "emits": [
      "add-entity",
      "create-rigid-body"
  ],
  setup (props, {emit}) {
    const scene = inject('scene'),
    camera = inject('camera'),
    mouseInfo = inject('mouseInfo'),
    rootNode = ref(),
    furryMesh = ref(null),
    fuzzyParams = ref(),
    createBox = (geom, position, rotation, inertia) => {

      const geometry = new THREE.BoxGeometry(geom.x, geom.y, geom.z),
        material = new THREE.MeshLambertMaterial({ color: 0xfcae1e }),
        mesh = new THREE.Mesh(geometry, material),
        node = new THREE.Object3D();

      node.position.set(position.x,position.y,position.z);
      node.add(mesh);

      const mass = 1,

       shape = {
        "type": "box",
        "box": {
          "size": [geom.x,geom.y,geom.z],
        }
      }

      emit("add-entity", {
        'name': 'box',
        node
      });

      emit("create-rigid-body", {
        node,
        shape,
        mass,
        inertia,
        kinematic: false
      });

    },
    createSphere = (geom, position, rotation, inertia) => {

      const sphereGeometry = new THREE.SphereGeometry(geom.x, geom.y, geom.z),
        material = new THREE.MeshLambertMaterial({ color: 0xfcae1e }),
        mesh = new THREE.Mesh(sphereGeometry, material),
        node = new THREE.Object3D();

      node.position.set(position.x,position.y,position.z);
      node.add(mesh);

      emit("add-entity", {
        'name': 'sphere',
        node
      });

      const shape = {
        "type": "sphere",
        "sphere": {
          "radius": geom.x,
        }
      },

       mass = 1;

      emit("create-rigid-body", {
        node,
        shape,
        mass,
        inertia,
        kinematic: false
      });
    
    },
    loadModel = (n,p) => {

      // Set up scene
      console.log('load entity n')
      console.log(n)

      const geometry = new THREE.BufferGeometry(),

        textureLoader = new THREE.TextureLoader(),

        loader = new GLTFLoader();

      loader.load(n.modelFile, (gltf) => {

          console.log('gltf');
          console.log(gltf);
          console.log('gltf.scene');
          console.log(gltf.scene);

          const physics = {};

          physics.shapes = gltf.userData?.gltfExtensions?.KHR_collision_shapes?.shapes
          physics.joints = gltf.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.physicsJoints
          physics.filters = gltf.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.collisionFilters
          physics.materials = gltf.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.physicsMaterials

          var animationMixer;

          if (gltf.animatitons > 0) {

              animationMixer = new THREE.AnimationMixer(gltf);

          } else {

              animationMixer = new THREE.AnimationMixer(gltf.scene);

          }
          gltf.scene.position.copy(p);

          parseNode(gltf.scene);

          gltf.scene.traverse((child)=> {

              parseNode(child, physics);

          });

          var animations = {};
          var inverseKenetics = {};
          for (var i=0; i < gltf.animations.length; i++) {

              animations[gltf.animations[i].name] = gltf.animations[i];

          }

          // TODO create a file format that wraps the gltf and allows additional info.

          emit("add-entity", {
            name: 'rabbit',
            node: gltf.scene,
            animations,
            animationMixer,
            actions: {},
            state: {}
          });

      });

    },
    parseNode = (node, physics, texture, normalMap) => {

        if (node.material) {

            console.log("node.material.name")
            console.log(node.material.name)

        }

        if ( node.name == "Rabbit_Realistic_LOD0") {

            const furryMeshData = furryMesh.value.generateFur(node);

            node.visible = false;

        }

        if (node.material?.name == "Fur New" && node.isMesh) {
            // if (node.material.userData.isFur == "true") {

            console.log("node");
            console.log(node);
            node.visible = false;
            //const furryMeshData = furryMesh.value.generateFur(node);
            
            //console.log(furryMeshData)
            // const { mesh, update } = furryMeshData;
            /*
            fuzzyParams.value = {

                geometry: new THREE.SphereGeometry(4, 32, 16, 0, Math.PI * 2, 0, Math.PI * 0.55),
                materialUniformValues: {
                    roughness: 1.0,
                },
                config: {
                    hairLength: 6,
                    hairRadiusBase: 0.5,
                    hairRadialSegments: 6,
                    gravity: 2,
                    fuzz: 0.25,
                    minForceFactor: 0.5,
                    maxForceFactor: 0.75,
                },

            }
            */
        }    
  /*
   * node.castShadow = true; //default is false
   * node.receiveShadow = false; //default
   *
   * // set initial rotation.
   * node.rotation.x = THREE.MathUtils.degToRad(180);
   *
   * const uvAttribute = node.geometry.getAttribute('uv');
   *
   * for (let i = 0; i < uvAttribute.count; i++) {
   *
   *    uvAttribute.setX(i, 1 - uvAttribute.getX(i));
   *
   * }
   *
   * uvAttribute.needsUpdate = true;
   */

    /*
     *
     *const material = new THREE.MeshStandardMaterial({
     *    metalness: 0.8,
     *    roughness: 0.1,
     *    wireframe: false,
     *    transparent: true,
     *    opacity: 1,
     *    depthWrite: false,
     *    bumpMap: texture,
     *    map: texture,
     *    normalMap,
     *    bumpScale: 0.5
     *});
     *node.material = material;
     *node.castShadow = true;
     *node.receiveShadow = false;
     *
     * // runtime vars
     *let initialRotation = 0;
     *let time = 0;
     *
     * // simple effect
     *const effect = function () {
     *
     *    material.opacity = 0.5;
     *    material.color = 0xe385f7;
     *
     *    setTimeout(()=> {
     *
     *        material.opacity = 1;
     *        material.color = "";
     *
     *    }, 10);
     *
     *}
     */

      if (node.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.joint) {

          console.log("it's a joint");
          console.log(node.userData.gltfExtensions.KHR_physics_rigid_bodies.joint)

      }

      if (node.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.collider) {

          console.log("it's a collider")
          console.log(node.userData.gltfExtensions.KHR_physics_rigid_bodies.collider)
          console.log(node)
          const mass = node.userData.gltfExtensions.KHR_physics_rigid_bodies.motion.mass | 1,
           shape_id = node.userData.gltfExtensions.KHR_physics_rigid_bodies.collider.shape | 0,
           shape = physics.shapes[shape_id];

          emit("create-rigid-body", {
            node,
            shape,
            mass,
            "inertia": null,
            "kinematic": true
          });

      }

    },
    reap = () => {};

    onMounted(() => {

    });

    onBeforeUnmount(() => {

    });

    return {
      scene,
      rootNode,
      parseNode,
      createBox,
      createSphere,
      loadModel,
      reap,
      fuzzyParams,
      furryMesh
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

</style>