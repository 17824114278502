<template>
    <div ref="rootNode">
    </div>
</template>

<script>
import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { FXAAShader } from 'three/addons/shaders/FXAAShader.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { inject, onBeforeUnmount, onMounted, provide, ref, toRef, toRaw, watch } from 'vue';

export default {
  name: 'Composer',
  "components": {
  },
  "props": {
      sceneLoaded: false
  },
  setup(props, {emit}) {

    var outlinePass;

    const rootNode = ref(),
    sceneLoaded = toRef(props.sceneLoaded),
    renderer = inject('renderer'),
    scene = inject('scene'),
    camera = inject('camera'),
    mouseInfo = inject('mouseInfo'),
    composer = ref(),
    width = ref(),
    height = ref(),
    keyPress = ref({}),
    init = () => {

      width.value = window.innerWidth;
      height.value = window.innerHeight;

      // Compoeser
      composer.value = new EffectComposer(renderer);

      const cam = camera.value.get();
      const renderPass = new RenderPass(scene, cam);

      composer.value.addPass(renderPass);

      // Tonemappng
      renderer.toneMapping = THREE.CineonToneMapping;
      renderer.toneMappingExposure = 1.5;
      renderer.outputColorSpace = THREE.SRGBColorSpace;

      // Bloom
      const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
      bloomPass.threshold = 1.5;
      bloomPass.strength = 1.0;
      bloomPass.radius = 8;

      const bloomComposer = new EffectComposer(renderer);
      bloomComposer.renderToScreen = false;
      bloomComposer.addPass(renderPass);
      bloomComposer.addPass(bloomPass);

      const mixPass = new ShaderPass(
          new THREE.ShaderMaterial({
            uniforms: {
                baseTexture: { value: null },
                bloomTexture: { value: bloomComposer.renderTarget2.texture }
            },
            vertexShader:
            `
            varying vec2 vUv;

            void main() {

            vUv = uv;

            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

            }`,
            fragmentShader:
            `
            uniform sampler2D baseTexture;
			uniform sampler2D bloomTexture;

			varying vec2 vUv;

			void main() {

				gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );

			}`,
            defines: {}
        }), 'baseTexture'
      );
      mixPass.needsSwap = true;

      //composer.value.addPass(mixPass);

      // Outline
      outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), scene, cam );
      //composer.value.addPass( outlinePass );

/*
      const glitchPass = new GlitchPass();
      composer.value.addPass( glitchPass )
*/
      const outputPass = new OutputPass();
      composer.value.addPass( outputPass );

/*
      const effectFXAA = new ShaderPass( FXAAShader );
      effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );
      composer.addPass( effectFXAA );
*/

    },
    addOutline = (node, opts) => {

        outlinePass.edgeStrength = Number( opts.edgeStrength );
        outlinePass.edgeGlow = Number( opts.edgeGlow);
        outlinePass.edgeThickness = Number( opts.edgeThickness);
        outlinePass.pulsePeriod = Number( opts.pulsePeriod);
        outlinePass.rotate = Number( opts.rotate);
        outlinePass.usePatternTexture = Number( opts.usePatternTexture);
        outlinePass.visibleEdgeColor.set( 0xffa500  );
        outlinePass.hiddenEdgeColor.set( 0x00ffff );
        outlinePass.selectedObjects = [ node ];

    };

    onMounted(() => {

        init();

        watch(
            () => sceneLoaded.value,

            (first, second) => {

              console.log("SCENE LOADED CHANED")
              if (sceneLoaded == true) {
 //               init();
              }

            }

        );

        watch(
            () => mouseInfo.value,

            (first, second) => {

                camera.value.orient();

                if (mouseInfo.value.buttons == 1) {

                }

            },
            { deep: true }
        );

    });

    onBeforeUnmount(() => {

        composer.value.dispose();

    });

    return {
      rootNode,
      keyPress,
      width,
      height,
      init,
      scene,
      composer,
      addOutline,
      sceneLoaded
    };
  },
};
</script>

<style>
    body {
        margin: 0;
    }

    #threeContainer {
        width: 100%;
        height: 100vh; /* Adjust as needed */
    }
</style>