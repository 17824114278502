<template>

    <div class="modal-dialog">
        <div class="flex-col">
            <input v-model="email"
                   class="emailpass"
                   placeholder="e-mail">
            <textarea v-model="text"
                      class="contact"
                      :placeholder="localize('TEXT_MESSAGE')" />
        </div>
        <div class="flex-col">
          <div id="send-button"
                  class="send-text"
                  @click="save">
              {{ localize('TEXT_SEND') }}
          </div>
          <div class="message-text">
            {{message}}
          </div>
        </div>
    </div>

</template>
<script>

import Axios from "axios";
import { translationStore } from "@/stores/translation";
import { emits, ref } from 'vue';

export default {
    name: 'Contact',
    "components": {
    },
    "props": {
    },
    "emits": [
        "close"
    ],
    setup(props, { emit }) {

        const translation = translationStore(),
            email = ref(),
            text = ref(),
            message = ref(),
            save = () => {

                console.log(email)
                console.log(text)

                Axios.get(

                    process.env.VUE_APP_SERVER_URI + "contact",
                    {
                        "params": {
                            "email": email.value,
                            "text": text.value
                        }
                    }

                ).
                then((response) => {

                    if (response.data) {

                        if (response.data.status === "success") {

                            message.value = translation.localize("CONTACT_WILL_CONTACT");
                            setTimeout(
                                () => {
                                  console.log('close')

                                    close();

                                },
                                2000
                            );

                        } else {

                            message.value = translation.localize("CONTACT_ERROR");

                        }

                    }

                }).
                catch((error) => {

                    console.log(error);

                });

            },
            emitHideModal = () => {

                emit(
                    "close",
                    false
                );

            },
            close = function () {

                emitHideModal();

            };

        return {
            "localize": translation.localize,
            emitHideModal,
            message,
            close,
            email,
            text,
            save
        };
    }
};

</script>
<style scoped>
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.message-text {
  color: orange;
}
h2 {
  margin-left: 20px;
  text-align: left;
  font-size: 24px;
  color: white
}
.content-container {
  margin-top: 150px;
}
.modal-dialog {
  pointer-events: all;
}
li.home-head {
  text-align: left;
  font-size: 1.1em;
  color: white
}
input.emailpass {
  font-size: 0.9em;
  text-indent: 10px;
  height: 30px;
  margin-bottom: 20px;
  border-bottom: 2px solid #efefef;
  border-radius: 10px;
  background-color: #fafafa;
  outline: 0;
}
textarea.contact {
    font-size: 1em;
    padding-top: 10px;
    min-height: 100px;
    max-width: 400px;
    text-indent: 10px;
    border-top: 2px white;
    border-left: 2px white;
    border-right: 2px white;
    border-bottom: 2px #efefef solid;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #fafafa;
    outline: 0;
}
.send-text{
  color: #fff;
  font-size: 0.9em;
  cursor: pointer;
}
.send-close {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.home-list {
  width: 250px;
}
.signup {
  width: 100px;
  margin-top: 10px;
  margin-left: 20px;
  color: white;
  height: 50px;
  font-weight: bold;
  background-color: #bf8505;
}
.send-inactive {
  border: 1px solid #f9b115;
  border-radius: 5px;
  background-color: #fff;
  color: #ababab;
  padding: 10px;
  cursor: pointer;
}
.send-active {
  border: 1px solid #f9b115;
  border-color: #f9b115;
  border-radius: 5px;
  background-color: #f9b115;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.signupmodal {
  margin-top: 10%;
}
.margin {
  margin-top: 100px;
}
.error {
  float: left;
  margin: 15px;
  color: grey;
}
.success {
  float: left;
  margin: 15px;
  color: #f9b115;
}
.close {
  color: #d0d0d0;
  margin: 10px;
}
.ud-login {
  display: block;
  height: 50px;
  width: 200px;
}
.ud-login:hover {
}
#message {
  font-size: 1em;
  transition: all 500ms ease-in-out;
}
</style>
