<template>
    <div>

        <div class="content-main-full">

            <div class="flex-col title-header">
                <div class="title-header-text">
                    The digital frontier.
                </div>
                <div class="site-text">
                </div>
            </div>

            <div class="main-border-outer">
                <div class="main-border-inner">
                    <div>
                        We are web3 and deeptech experts out of Japan and the EU focused on cutting edge solutions through tokenization, from real world assets(RWA) to gaming and regenerative finance.
                        Just like the internet transformed business online, tokenization is set to reshape finance and how we interact with money.
                        In this new world, we reimagine all interactions as tangible digital assets that can be bought and sold without middlemen or counterparty risk.
                    </div>
                    <br />
                    <div>
                        But why is tokenization inevitable ? It's simple, tokenized asset transactions are much cheaper, enable lightning fast settlement, run 24/7, no bank holidays, massively reduced counterparty risk, transparency and the list goes on.
                        Simply to say, it is the path of least resistance. Contact us and join the decentralzed revolution.
                    </div>
                </div>
            </div>

        </div>

        <div :class="threeSegments">

            <div :class="contentMainDevice">

                <div class="flex-col title-header">
                    <img class="ninja-header" src="../assets/ninjausagi1.png" />
                    <div class="title-header-text">
                        Development.
                    </div>
                    <div class="site-text">
                    </div>
                </div>

                <div class="main-border-outer">
                    <div class="main-border-inner">
                        Our tech team founder is an ex-Google silicon valley veteran with many decades of experience building solutions for many of the worlds leading companies. We build full web3 and deep tech solutions for industry from gaming to real world asset tokenization.
                        We have experize in decentralized finance and smart contract technology to build custom software for your use case.
                    </div>
                </div>

            </div>

            <div :class="contentMainDevice">

                <div class="flex-col title-header">
                    <img class="ninja-header" src="../assets/ninjausagi2.png" />
                    <div class="title-header-text">
                        Consulting.
                    </div>
                    <div class="site-text">
                    </div>
                </div>

                <div class="main-border-outer">
                    <div class="main-border-inner">
                        We offer comprehensive web3 strategy and consulting services. Decades of enterprise experience and deep engineering knowledge empower us with unparalleled insights to help you and your company navigate this complex and often misunderstood space.
                        Allow our network and connections to power your journey from knowledge building, data insights, finance, and a host of other services.
                    </div>
                </div>

            </div>

            <div :class="contentMainDevice">

                <div class="flex-col title-header">
                    <img class="ninja-header" src="../assets/ninjausagi3.png" />
                    <div class="title-header-text">
                        Partnerships.
                    </div>
                    <div class="site-text">
                    </div>
                </div>

                <div class="main-border-outer">
                    <div class="main-border-inner">
                        We have spent the last 5 years building international business partnerships in the web3 space, through our network we can assist with product and token/NFT launches, marketing, and strategy to ensure visibilty and compliance on a global scale.
                        This is a key consideration when engaging in a new venture, we are excited to assist your business to grow in this space. Please do not hesitate to contact us, contact@compdeep.com
                    </div>
                </div>

            </div>

        </div>

        <div class="content-main-full">

            <div class="flex-col title-header">
                <br /><br />
                <div class="title-header-text">
                    Projects.
                </div>
                <br /><br />
                <div class="site-text">
                </div>
            </div>

            <div :class="flexDirection">

                <div :class="projectItem">

                    <div class="main-border-outer">

                        <img style="width: 100%" src="../assets/ninjausagi4.png" />

                        <div class="flex-col">
                            <div class="flex-col title-header">
                                <div class="svg-logo pointer"
                                     v-html="getIconRaw('SHINOVI_LOGO')" />
                                <div class="title-header-text">
                                    ShinoVi
                                </div>
                            </div>
                            <div class="main-border-inner">
                                <a class="plain-link" href="www.shinovi.io">ShinoVi</a> is a decentralized gaming platform, tokenizing both art in game assets as NFTs (Non Fungible Tokens). ShinoVi was primarily built as a Japanese web3 community for artists however we are now transitioning it to a one stop Gaming platform.
                                Web3 gaming is set to be a multi-trillion dollar market going forward with a vast virtual economy of digital assets. This includes the metaverse for education, tourism, gaming, sports and much more, the possibilities are virtually endless and ShinoVi will be an integral part of our enagement.
                                ShinoVi offers incredible opportunity.
                            </div>
                        </div>
                        <!--
                <ul>
                    <li><b>Economies of scale</b>, web3 enables a free market economy with price discovery of virtual assets which is completely absent in the traditional gaming world.</li>
                    <li><b>Play to earn</b>, web3 games allow players to earn for their interactions, bringing a new player base building powerful micro-economies.</li>
                    <li><b>Creator Economoy</b> Creators can now create their own items, or art and directly monetize this as in game purchases.</li>
                    <li><b>Royalties </b> NFTs allow royalties and fees to be processed on each transaction ensuring all paries are paid correctly without error.</li>
                    <li><b>Community </b> Community is inherent in web3 due to the economy aspect, web3 games offer more interactive experiences and opportunities to players.</li>
                </ul>
                    -->
                    </div>
                </div>

                <div :class="projectItem">

                    <div class="main-border-outer">
                        <img style="width: 100%" src="../assets/ninjausagi.png" />

                        <div class="flex-col">
                            <div class="flex-col title-header">
                                <div class="svg-logo pointer"
                                     v-html="getIconRaw('RAREIUM_LOGO')" />
                                <div class="title-header-text">
                                    Rareium
                                </div>
                            </div>
                            <div class="main-border-inner">
                                <a class="plain-link" href="www.rareium.io">Rareium.io</a> is an upcoming decentralized rare earth metals exchange, that provides investors an easy to access method of investing in rare earth commodities.
                                Every Rareium Token is backed by one kilogram of industry quantity rare earth metals, securely stored in a bank grade facility. Tokenization enables the purchase of fractional amounts of metals, and can automatically adjust to cover storage fees.
                                The blockchain brings increased liquidity, enabling  global access and immediate settlement, 24/7. Furthermore all transactions are fully public, bringing trust and visibility to the market.
                            </div>
                            <!--
                    <ul>
                        <li><b>Fractional purchases</b>, while traditionally the minimum investment amount was $10,000</li>
                        <li><b>Immediate settlement</b>, when a token is purchased, it is settled in near real time, and our contractual obligations guarantee the actual physical metal.</li>
                        <li><b>24/7</b> , there are no opening or closing times, the blockchain is always active, buy and sell orders are always open.</li>
                        <li><b>Global Scale</b>, while previously the paper contracts and business model was generally limited, the blockchain offers immediate global access.</li>
                        <li><b>Increased Liquidity</b>, blockchain technology allows anyone to provide liqudity pairs to facilite trades, these are options unavailable in traditional finance.</li>
                        <li><b>Transparency</b> transactions on the blockchain are totally open and transparent, meaning the volume and purchases can be seen in real time and are trustworthy.</li>
                        <li><b>Efficiency</b> smart contracts enable distribution of funds immediately without third parties, each participant/fee is always processed correctly and immediately. </li>
                    </ul>
                        -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-col title-header">
                <br /><br />
                <div class="title-header-text">
                    Partnerships.
                </div>
                <br /><br />
                <div class="partnerships-container">
                    <div class="partnerships-items">
                        <img class="partnership-image" src="../assets/matsumoto.png" />
                    </div>
                    <div class="partnerships-items">
                        <img class="partnership-image" src="../assets/0_7C4c-JdFcK3iES5P.png" />
                    </div>
                    <div class="partnerships-items">
                        <img class="partnership-image" src="../assets/logo-1.png" />
                    </div>
                    <div class="partnerships-items">
                        <img class="partnership-image" src="../assets/Strategic-Metals-Invest-Logo-361x60-1.jpg.webp" />
                    </div>
                </div>

            </div>

        </div>

        <CModal v-if="showPDFModal"
                width="medium"
                color="#5d328d"
                :title="thisPDF"
                @close="showPDFModal = false">
            <template #header>
                <div> Edit Admin </div>
            </template>
            <template #body>
                <div class="flex-col">
                    <embed :src="thisPDF"
                        frameborder="0"
                        width="100%"
                        height="800px">
                </div>
            </template>
            <template #footer>
                <div class="hidden" />
            </template>
        </CModal>

    </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Home",
    "components": {
    },
    "props": {
    },
    setup () {

        const icon = iconStore(),
            router = inject("router"),
            serverConfig = inject("serverConfig"),
            translation = translationStore(),
            thisPDF = ref(""),
            threeSegments = ref("three-segments-desktop"),
            contentMainDevice = ref("content-main-full"),
            projectItem = ref("content-main-full"),
            flexDirection = ref("flex-row"),
            gotoUrl = function (url) {

                window.location = url;

            },
            isLang = function (lang) {

                console.log(translation.getLang().indexOf(lang));
                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            gotoTab = function (t) {

                router.push({"path": `/${t}`,
                    "name": t});

            },
            updateView = function () {

                console.log("updateView")
                console.log(serverConfig.view)

                if (serverConfig.view === "desktop") {

                    console.log('desktop');
                    contentMainDevice.value = "content-main-small";
                    threeSegments.value = "three-segments-desktop";
                    projectItem.value = "project-item-desktop";
                    flexDirection.value = "flex-row-full";

                    console.log("contentMainDevice.value")
                    console.log(contentMainDevice.value)
                    console.log(threeSegments.value)

                } else if (serverConfig.view === "laptop") {
                    console.log('laptop');

                    contentMainDevice.value = "content-main-small";
                    threeSegments.value = "three-segments-desktop";
                    projectItem.value = "project-item-desktop";
                    flexDirection.value = "flex-row-full";

                } else if (serverConfig.view === "tablet") {
                    console.log('tablet');

                    contentMainDevice.value = "content-main-small";
                    threeSegments.value = "three-segments-desktop";
                    projectItem.value = "project-item-desktop";
                    flexDirection.value = "flex-row-full";

                } else if (serverConfig.view === "mobile") {
                    console.log('mobile');

                    contentMainDevice.value = "content-main-full";
                    threeSegments.value = "three-segments-mobile";
                    projectItem.value = "project-item-mobile";
                    flexDirection.value = "flex-col-full";

                } else {

                    console.log('mobile');
                    contentMainDevice.value = "content-main-full";
                    threeSegments.value = "three-segments-mobile";
                    projectItem.value = "project-item-mobile";
                    flexDirection.value = "flex-col-full";

                }
                console.log('RR');

            },
            getIconRaw = function (i) {

                return icon.get(`${i}`);


            },
            showPDFModal = ref(false);

        onMounted(() => {

            setTimeout(
                () => {

                    updateView();

                    watch(
                        () => serverConfig.view,
                        () => {

                            console.log("serverConfig change " + serverConfig.view);

                            updateView();

                        }
                    );

                },
                500
            );

        });

        return {"localize": translation.localize,
            translation,
            "icon": icon.get,
            getIconRaw,
            gotoTab,
            gotoUrl,
            isLang,
            thisPDF,
            updateView,
            contentMainDevice,
            threeSegments,
            showPDFModal,
            serverConfig,
            projectItem,
            flexDirection,
            showPDF};

        }

};

</script>
<style scoped>
    .main-border-outer {
        background-color: #fff;
        margin: 10px;
        border-radius: 5px;
        -webkit-clip-path: polygon(15px 0, 100% 0, 100% 10px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 100%, 0 15px);
        clip-path: polygon(15px 0, 100% 0, 100% 10px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 100%, 0 15px);
        background: linear-gradient(9deg, #000000d9, #000000b0);
    }
    .main-border-inner {
        color: white;
        font-size: 1em;
        border-radius: 5px;
        padding: 20px;
        -webkit-clip-path: polygon(15px 0, 100% 0, 100% 10px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 100%, 0 15px);
        clip-path: polygon(15px 0, 100% 0, 100% 10px, 100% calc(100% - 15px), cavlc(100% - 15px) 100%, 0 100%, 0 100%, 0 15px);
    }
    .content-main-small {
        max-width: 500px;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9em;
        flex-direction: column;
        background-position: 0 0;
    }
    .content-main-full {
        max-width: 1200px;
        border: 10px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
        flex-direction: column;
        background-position: 0 0;
    }
    .three-segments-desktop {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
    }
    .three-segments-mobile {
        display: flex;
        flex-direction: column;
        border: 10px;
    }
    .title-header {
        padding: 10px;
        justify-content: space-evenly;
        align-items: center;
    }
    .title-header-text {
        margin-left: 15px;
        font-size: 2em;
        color: white;
    }
    .title-header-text-large {
        margin-left: 15px;
        font-size: 2.4em;
        color: white;
    }
    .site-text {
        font-size: 1em;
        color: white;
    }
    .footer-item {
      margin: 10px;
      cursor: pointer;
      color: #1d1d1d;
    }
    .footer-item:hover {
      text-decoration: underline;
    }
    .discord {
      margin: 32px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      background-image: url("../assets/discord.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .twitter {
      margin: 32px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      background-image: url("../assets/x.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .footer-social {
      margin-top: -50px;
      border-radius: 33%;
      display: flex;
      flex-direction: row;
      border-radius: 70%;
    }
    .footer-links {
      color: white;
      display: flex;
      flex-direction: row;
    }
    .plain-link {
      text-decoration: none;
      color: #afafaf;
    }
    .ninja-header {
      width: 100%;
      max-width: 300px;
    }
    .project-item-mobile {
      width: 100%;
    }
    .project-item-desktop {
      width: 50%;
    }
    .flex-row-full {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .flex-col-full {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .partnerships-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
    }
    .partnerships-items {
        
    }
    .partnership-image {
        max-width: 300px;
        margin: 20px;
    }
</style>

