
<template>
    <div ref="rootNode" class="root-container">
        <div class="overlay">
        </div>
        <div ref="three" class="three-container">

            <video id="video"
                   loop
                   muted
                   crossOrigin="anonymous"
                   style="display:none;width:100%;height:100%">
                <source src="../assets/video.mp4">
            </video>
            <video id="depthvideo"
                   loop
                   muted
                   crossOrigin="anonymous"
                   style="display:none;width:100%;height:100%">
                <source src="../assets/videoDepth.mp4">
            </video>
            <video id="bgvideo"
                   loop
                   muted
                   crossOrigin="anonymous"
                   style="display:none;width:100%;height:100%">
                <source src="../assets/ocean3.mp4">
            </video>
        </div>

        <Controls ref="controls"
                  @key-press="captureKeyPress"
                  @mouse-info="captureMouseInfo" />
        <Camera ref="camera" />
        <Entity ref="entityComponent"
                @add-entity="addEntity"
                @create-rigid-body="createRigidBody" />

        <Actions ref="actionsComponent" />
        <!--

    <Physics
      v-if="physicsLoaded == true"
      ref="physicsComponent"
      :entities="entities"
      @add-to-scene="addToScene"
    />
      -->
        <Composer v-if="sceneLoaded == true"
                  ref="composerComponent"
                  :scene-loaded="sceneLoaded" />
    </div>
</template>

<script>
    import * as THREE from 'three';
    import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
    import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
    import { nextTick, onBeforeUnmount, onMounted, onUnmounted, provide, ref, toRaw, watch } from 'vue';
    import { useRoute } from "vue-router";

    import Axios from "axios";
    //import * as AmmoJS from 'ammojs3';
    //import { utils } from '@/utils/utils';
    //import Stats from 'stats.js';
    import Entity from './Entity.vue';
    import Controls from './Controls.vue';
    import Camera from './Camera.vue';
    //import Physics from './Physics.vue';
    import Composer from './Composer.vue';

    //import Actions from './Actions.vue';
    import depthMap from '../assets/depthmap1.png';
    //import depthMap from '../assets/kandao3_depthmap.jpg';
    import textureMap from '../assets/testrend1.png';
    //import textureMap from '../assets/kandao3.jpg';
    

    export default {
        name: 'Scene',
        "components": {
            Controls,
            Camera,
            Entity,
            //Actions,
            //Physics,
            Composer
        },
        setup() {

            const rootNode = ref(),
                route = useRoute(),
                //stats = ref(new Stats()),
                width = ref(),
                height = ref(),
                deltaX = ref(0),
                deltaY = ref(0),
                currentX = ref(0),
                currentY = ref(0),
                keyPress = ref({}),
                //ammoLib = ref({}),
                mouseInfo = ref({
                    clientX: 0,
                    clientY: 0,
                    deltaX: 0,
                    deltaY: 0,
                    dragDeltaX: 0,
                    dragDeltaY: 0,
                    buttons: 0
                }),
                scene = new THREE.Scene(),
                renderer = new THREE.WebGLRenderer({ alpha: true }),
                clock = new THREE.Clock(),
                axesHelper = new THREE.AxesHelper(5),
                gridHelper = new THREE.GridHelper(15, 15),
                time = ref(0),
                three = ref(null),
                camera = ref(null),
                lastScrollPos = ref(window.scrollY),
                entityComponent = ref(null),
                //actionsComponent = ref(null),
                //physicsComponent = ref(null),
                //physicsLoaded = ref(false),
                composerComponent = ref(null),
                entities = ref({}),
                cursor = ref(),
                sceneLoaded = ref(false),
                intersected = ref(),
                displacementShaderUniforms = ref(),
                geometryShaderUniforms = ref(),
                objects = ref({}),
                createCursor = () => {

                    const cursorGeometry = new THREE.BufferGeometry(),
                        vertices = new Float32Array([
                            -0.1, 0, 0,
                            0.1, 0, 0,
                            0, -0.1, 0,
                            0, 0.1, 0
                        ]);
                    cursorGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

                    const cursorMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

                    cursor.value = new THREE.Mesh(cursorGeometry, cursorMaterial);
                    scene.add(toRaw(cursor.value));

                },
                loadEntities = () => {

                    console.log('test function');
                    console.log(`${process.env.VUE_APP_SERVER_URI}public/rabbit.json`);
                    console.log(Axios)
                    Axios.get(

                        `${process.env.VUE_APP_SERVER_URI}public/rabbit.json`,
                        {

                            "headers": {
                            }

                        }

                    ).
                        then((response) => {

                            console.log('response')
                            console.log(response.status)
                            console.log(response.data)

                            if (response.status === 200) {

                                console.log(response.data);
                                console.log("JSON.parse(response.data)");
                                const n = response.data;
                                console.log(n)
                                console.log(n.model)
                                console.log(n.model.entities)

                                console.log('n.model.entities[0]')
                                console.log(n.model.entities[0])
                                entityComponent.value.loadModel(n.model.entities[0], new THREE.Vector3(0, 0, 0));

                            }

                        }).
                        catch((error) => {
                            console.log('error')
                            console.log(error)
                        });

                },
                createRigidBody = (node) => {

                    physicsComponent.value.createRigidBody(node.node, node.shape, node.mass, node.inertia, node.kinematic);

                },
                captureKeyPress = (o) => {

                    keyPress.value = o.value;

                },
                captureMouseInfo = (o) => {

                    mouseInfo.value = o.value;
                    // mouseInfo.value.buttons = o.value.buttons;

                },
                initScene = () => {

                    width.value = window.innerWidth;
                    height.value = window.innerHeight;

                    renderer.setPixelRatio(window.devicePixelRatio);
                    renderer.setSize(width.value, height.value);
                    three.value.appendChild(renderer.domElement);
                    //scene.add(axesHelper);
                    //scene.add(gridHelper);

                    const geometry = new THREE.BufferGeometry(),
                        textureLoader = new THREE.TextureLoader(),
                        loader = new GLTFLoader()
                            .setMeshoptDecoder(MeshoptDecoder),

                        // Lighting
                        ambientLight = new THREE.AmbientLight(0x404040, 0.1);

                    scene.add(ambientLight);

                    const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
                    directionalLight.position.set(0, 1, 1);
                    scene.add(directionalLight);

                    // can only be loaded after the renderer is setup

                    sceneLoaded.value = true;

                },
                addEntity = (entity) => {

                    scene.add(entity.node);
                    entities.value[entity.name] = entity;

                },
                addToScene = (e) => {

                    switch (e.type) {

                        case "box":
                            if (!entities.value[e.name]) {

                                entities.value[e.name] = {}

                            }
                            scene.add(e.node);
                            break;

                        /*
                         *const boxGeometry = new THREE.BoxGeometry(e.size.x, e.size.y, e.size.z);
                         *const boxMaterial = new THREE.MeshPhongMaterial({
                         *  color: 0x02ced9,
                         *  specular: 0x222222,
                         *  shininess: 35,
                         *});
                         *let ent = new THREE.Mesh(boxGeometry, boxMaterial);
                         *ent.userData['rigidBody'] = e.rigidBody;
                         *
                         *scene.add(ent);
                         *
                         *if (!entities.value[e.name]) {
                         *
                         *  entities.value[e.name] = {}
                         *
                         *}
                         *entities.value[e.name].physicsEntity = ent;
                         *break;
                         */

                    }

                },
                removedRigidBody = (e) => {

                },
                animate = function (delta) {

                    let prod = true;

                    if (prod == true) {

                        // camera.value.camera.lookAt(new THREE.Vector3(-10,-10,-0))

                    }

                    // start stats
                    // stats.value.begin();
                    // animations

                    for (const i in entities.value) {

                        if (entities.value[i].animationMixer) {

                            entities.value[i].animationMixer.update(delta);

                        }

                    }

                    // camera
                    camera.value.process();

                    if (scene.children[5]) {

                        //actionsComponent.value.aimAt2(scene.children[5], scene.children[scene.children.length - 1].position, true);

                        /*
                         *getRootNode(scene.children[5], (rootNode) => {
                         *
                         *    let target = null;
                         *    if (rootNode) {
                         *
                         *        if (scene.children.length > 6) {
                         *
                         *            actionsComponent.value.aimAt2(rootNode, scene.children[scene.children.length-1].position, true);
                         *
                         *        }
                         *
                         *    }
                         *
                         *});
                         */

                    }
                    /*
                    displacementShaderUniforms.value.cameraposition.value.copy(
                        camera.value.camera.position.clone()
                    );
                    */

                    const localCameraPosition = new THREE.Vector3();
                    localCameraPosition.copy(camera.value.camera.position);

                    //if (objects.value['mesh']) {
                    //    objects.value['mesh'].worldToLocal(localCameraPosition);
                    //}

                    //displacementShaderUniforms.value.cameraposition.value.copy(localCameraPosition);

                    if (displacementShaderUniforms.value) {
                        displacementShaderUniforms.value.cameraposition.value.lerp(
                            localCameraPosition, 0.1
                        );
                    }
                    
                    geometryShaderUniforms.value.u_time.value = performance.now() / 1000;

                    // action
                    renderer.render(scene, camera.value.camera);

                    // composer
                    toRaw(composerComponent.value.composer).render();

                    // end stats
                    //stats.value.end();

                    // repeat
                    requestAnimationFrame(() => { animate(clock.getDelta()) });

                },
                rayCastPointer = () => {

                    const cam = camera.value.get(),
                        mousePos = new THREE.Vector3(mouseInfo.value.clientX, mouseInfo.value.clientY, 0.5),
                        forward = new THREE.Vector3(0, 0, 1);

                    mousePos.unproject(cam);

                    const vec = mousePos.sub(cam.position).normalize(),
                        dst = -cam.position.z / vec.z,
                        pos = cam.position.clone().add(vec.multiplyScalar(dst));

                    if (mouseInfo.value.buttons == 1) {
                        /*
                        const raycaster = new THREE.Raycaster(),
                            pointer = new THREE.Vector2();

                        pointer.x = (mouseInfo.value.clientX / window.innerWidth) * 2 - 1;
                        pointer.y = - (mouseInfo.value.clientY / window.innerHeight) * 2 + 1;

                        raycaster.setFromCamera(pointer, cam);

                        const intersects = raycaster.intersectObjects(scene.children
                            .filter(object => (object !== gridHelper && object !== axesHelper)));

                        if (intersects.length == 0) { return { vector: forward }; }

                        const intersect = filterIntersects(intersects);

                        if (intersect) {

                            const intersectionPoint = intersect.point,
                                node = intersect.object,
                                vector = intersectionPoint.project(cam),
                                x = (vector.x + 1) / 2 * window.innerWidth,
                                y = -(vector.y - 1) / 2 * window.innerHeight,
                                highlightDiv = document.createElement('div'),

                                opts = {
                                    edgeStrength: 3.0,
                                    edgeGlow: 0.0,
                                    edgeThickness: 2.0,
                                    pulsePeriod: 0,
                                    rotate: false,
                                    usePatternTexture: false
                                };

                            composerComponent.value.addOutline(node, opts);

                            console.log('node vec');
                            console.log({ vector, node });

                            return { vector, node };

                        }
                        */
                    }

                    return { vector: forward };

                },
                /*
                 * Here we filter for visual model objects.
                 * TODO : expand this for selectable physics, armature and whatnot
                 */
                filterIntersects = (intersects) => {

                    console.log(`filterIntersects length ${intersects.length}`)
                    console.log(intersects)

                    for (let i = 0; i < intersects.length; i++) {

                        const node = intersects[i].object;
                        /*
                         *if (node.parent?.userData?.isPhysicsNode == true) {
                         *
                         *    continue;
                         *
                         *}
                         *
                         *if (node.userData?.hasPhysics == true) {
                         *
                         *    continue;
                         *
                         *}
                         */
                        if ((node.type == "Mesh" || node.type == "SkinnedMesh")
                            && node.visible == true) {

                            console.log('selected')
                            console.log(node)
                            return intersects[i];

                        }

                    }

                },
                runScene = () => {
                    /*
                    scene.background = new THREE.Color(0x101010);

                    const light = new THREE.AmbientLight(0xffffff, 3);
                    scene.add(light);

                    // Create the panoramic sphere geometery
                    const panoSphereGeo = new THREE.SphereGeometry(12, 512, 512);

                    // Create the panoramic sphere material
                    const panoSphereMat = new THREE.MeshStandardMaterial({
                        side: THREE.BackSide,
                        displacementScale: - 4.0
                    });

                    // Create the panoramic sphere mesh
                    const sphere = new THREE.Mesh(panoSphereGeo, panoSphereMat);

                    // Load and assign the texture and depth map
                    const manager = new THREE.LoadingManager();
                    const loader = new THREE.TextureLoader(manager);

                    loader.load(textureMap, function (texture) {

                        console.log('loaded texture')

                        texture.colorSpace = THREE.SRGBColorSpace;
                        texture.minFilter = THREE.NearestFilter;
                        texture.generateMipmaps = false;
                        sphere.material.map = texture;

                    });

                    loader.load(depthMap, function (depth) {

                        console.log('loaded deptmap')

                        depth.minFilter = THREE.NearestFilter;
                        depth.generateMipmaps = false;
                        sphere.material.displacementMap = depth;

                    });

                    // On load complete add the panoramic sphere to the scene
                    manager.onLoad = function () {

                        scene.add(sphere);

                    };
                    */
                   
                    camera.value.camera.position.x = 24.334185778963025;
                    camera.value.camera.position.y = -16.711596840784935;
                    camera.value.camera.position.z = -4.855794505704958;

                    camera.value.camera.rotation.x = 0.7275629692382871;
                    camera.value.camera.rotation.y = 0.8447170706816672
                    camera.value.camera.rotation.z = 0.687327459966743;
                    
                    //camera.value.camera.position = new THREE.Vector3(24.334185778963025, -16.711596840784935, -4.855794505704958);
                    //camera.value.camera.rotation = new THREE.Vector3(0.7275629692382871, 0.8447170706816672, 0.687327459966743);

                },
                runVideo = () => {

                    camera.value.camera.position.z = 33.0;

                    // load video
                    const video = document.getElementById('video'),
                        depthVideo = document.getElementById('depthvideo'),
                        texture = new THREE.VideoTexture(video),
                        depthTexture = new THREE.VideoTexture(depthVideo);

                    texture.minFilter = THREE.NearestFilter;
                    texture.colorSpace = THREE.SRGBColorSpace;
                    depthTexture.minFilter = THREE.NearestFilter;
                    texture.generateMipmaps = false;
                    depthTexture.generateMipmaps = false;

                    console.log('video width height');
                    console.log(video.videoWidth);
                    console.log(video.videoHeight);

                    // lights
                    const light = new THREE.AmbientLight(0xffffff, 3);
                    scene.add(light);

                    //const aspectRatio = video.videoWidth / video.videoHeight;

                    const renderGeometry = new THREE.PlaneGeometry(width.value / 16, height.value / 16, width.value / 10, height.value / 10);
                    

                    // Create the panoramic sphere geometery
                    // const renderGeometry = new THREE.SphereGeometry(128, 256, 256);

                    // Create the panoramic sphere material
                    //const panoSphereMat = new THREE.MeshStandardMaterial({
                    //    side: THREE.BackSide,
                    //    displacementScale: - 4.0
                    //});

                    const planeVertices = renderGeometry.attributes.position.array;

                    // uvs
                    const uvs = renderGeometry.attributes.uv.array;

                    // render material
                    const renderMaterial = new THREE.MeshStandardMaterial({
                        map: texture,
                        side: THREE.BackSide,
                        displacementScale: - 4.0
                    });
                    //renderMaterial.displacementMap = depthTexture;

                    //vUv = uv;
                    //vec4 color = texture2D(map, vUv);
                    //geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

                    renderGeometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                    renderGeometry.setAttribute('position', new THREE.BufferAttribute(planeVertices, 3));
                    //renderGeometry.geometry.computeVertexNormals(true);

                    displacementShaderUniforms.value = {

                        'map': { value: texture },
                        'depthMap': { value: depthTexture },
                        'width': { value: width.value / 10 },
                        'height': { value: height.value / 10 },
                        //'cameraposition': { type: 'v3', value: camera.value.camera.position }
                        'cameraposition': { type: 'v3', value: new THREE.Vector3(0, 0, 0) }

                    }

                    /*
                    displacementShaderUniforms.value.cameraposition.value.copy(
                        camera.value.camera.position.clone().add(new THREE.Vector3(1, 1, 1))
                    );
                    */

                    // Dispalcement Material
                    const displacementMaterial = new THREE.ShaderMaterial({
                        uniforms: displacementShaderUniforms.value,
                        vertexShader: `
                            varying vec2 vUv;
                            uniform sampler2D map;
                            uniform sampler2D depthMap;
                            uniform float width;
                            uniform float height;
                            uniform vec3 cameraposition;
                            
                            void main() {
                                vUv = uv;
                                
                                vec4 color = texture2D( depthMap, vUv );
                                float z = 1.0 - ( color.r + color.g + color.b ) / 3.0;


                                vec3 inDir = normalize(position - vec3(0.0,0.0,0.0));
                                vec3 outDir = normalize(vec3(0.0,0.0,0.0) - position);

                                vec3 zero = vec3(0.0,0.0,0.0);

                                vec3 camDir = normalize(cameraposition - position);
                                //vec3 camDir = normalize(vec3(0.0,0.0,100.0) - position);

                                vec3 finalWorldPosition = position + camDir * z * 20.0;

                                // finalWorldPosition.x *= 1.0 + z / 10.0;
                                // finalWorldPosition.y *= 1.0 + z / 10.0;

                                // Step 6: Apply the model-view-projection transformation
                                gl_Position = projectionMatrix * viewMatrix * vec4(finalWorldPosition, 1.0);

                            }
                            
                        `,
                        fragmentShader: `
                          varying vec2 vUv;
                          uniform sampler2D map;
                          uniform sampler2D depthMap;
                          uniform vec3 cameraposition;

                          vec3 sRGBToLinear(vec3 color) {

                              return pow(color, vec3(2.2));

                          }

                          void main() {

                            vec4 disp = texture2D(map, vUv);
                            gl_FragColor = vec4(sRGBToLinear(disp.rgb), 1.0);

                          }

                        `
                    });

                    displacementMaterial.displacementMap = depthTexture;

                    // flip for panorama
                    //displacementMaterial.side = THREE.BackSide;

                    const mesh = new THREE.Mesh(renderGeometry, displacementMaterial);
                    mesh.name = "displacement";
                    mesh.material.overdraw = true;
                    mesh.material.shading = THREE.SmoothShading;

                    // mesh.lookAt(camera.value.camera.position);
                    mesh.renderOrder = 2;
                    mesh.geometry.attributes.position.needsUpdate = true;
                    mesh.geometry.computeVertexNormals();

                    // DISABLE
                    scene.add(mesh);
                    objects.value['mesh'] = mesh;
                    mesh.position.set(0.0, 0.0, 0);

                    // extract vertices
                    const w = width.value / 16, h = height.value / 16,
                        nearClipping = 0, farClipping = 10,
                        vertices = new Float32Array(w * h * 6);

                    for (let i = 0, j = 0; i < w * h * 6; i += 6, j++) {

                        // Original vertex
                        vertices[i] = j % w;
                        vertices[i + 1] = Math.floor(j / w);
                        vertices[i + 2] = 0;

                        // Trailing vertex (initially at the same position)
                        vertices[i + 3] = j % w;
                        vertices[i + 4] = Math.floor(j / w);
                        vertices[i + 5] = 0;

                    }

                    const geometry = new THREE.BufferGeometry();
                    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

                    geometryShaderUniforms.value = {

                        'map': { value: depthTexture },
                        'width': { value: w },
                        'height': { value: h },
                        'nearClipping': { value: nearClipping },
                        'farClipping': { value: farClipping },
                        'u_time': { value: 0.1 },
                        'pointSize': { value: 2 },
                        'zOffset': { value: 0 }

                    }

                    const material = new THREE.ShaderMaterial({

                        uniforms: geometryShaderUniforms.value,
                        vertexShader: `

                            uniform sampler2D map;

                            uniform float width;
                            uniform float height;
                            uniform float nearClipping, farClipping;

                            uniform float pointSize;
                            uniform float zOffset;
                            uniform float u_time;

                            varying vec2 vUv;
                            varying float depth;

                            const float XtoZ = 1.11146; // tan( 1.0144686 / 2.0 ) * 2.0;
			                const float YtoZ = 0.83359; // tan( 0.7898090 / 2.0 ) * 2.0;

                            void main() {

                                vUv = vec2( position.x / width , position.y / height );

                                float z = ( 1.0 - depth ) * (farClipping - nearClipping) + nearClipping;
                                depth = z;

			                    const float XtoZ = 1.11146; // tan( 1.0144686 / 2.0 ) * 2.0;
			                    const float YtoZ = 0.83359; // tan( 0.7898090 / 2.0 ) * 2.0;

                                float bro;

                                // Apply sine wave to trailing vertices
                                if (int(gl_VertexID) % 2 == 1) {

                                  bro = sin(position.x * u_time / 30.0);

                                } else {

                                  bro = cos(-position.x * u_time*10 / 3.0)/3.0;

                                }

                                // gl_Position = projectionMatrix
                                // * modelViewMatrix
                                // * vec4(position, 1.0);

                                vec4 pos = vec4(
                                //(position.x - width / 2.0) * XtoZ,
                                //(position.y - height / 2.0) * YtoZ,
                                //(position.x / 4.0 - width / 8.0) * XtoZ,
                                //(position.y / 4.0 - height / 8.0) * YtoZ,
                                //position.x - width / 2.0 * XtoZ,
                                //position.y - height / 2.0 * YtoZ,

                                //position.x - (width - (width / 40.0)) / 2.0,
                                //position.y - (height - (height / 40.0)) / 2.0,

                                position.x - (width / 2.0) + width / 160.0,
                                position.y - (height / 2.0) + height / 160.0,

                                bro - z, // - z,
                                1.0);

                                gl_PointSize = pointSize;
                                gl_Position = projectionMatrix * modelViewMatrix * pos;

                               
                               // vUv = vec2( position.x / width , position.y / height );

                               // vec4 color = texture2D( map, vUv );
                               // float depth = ( color.r + color.g + color.b ) / 3.0;

                               // Projection code by @kcmic
                               // float z = ( 1.0 - depth ) * (farClipping - nearClipping) + nearClipping;
                               // depth = z;

			                   // const float XtoZ = 1.11146; // tan( 1.0144686 / 2.0 ) * 2.0;
			                   // const float YtoZ = 0.83359; // tan( 0.7898090 / 2.0 ) * 2.0;

                               // vec4 pos = vec4(
                               //     (position.x - width / 2.0) * XtoZ,
                               //     (position.y - height / 2.0) * YtoZ,
                               //     - z,
					           //     1.0);

                               // gl_PointSize = pointSize;
                               // gl_Position = projectionMatrix * modelViewMatrix * pos;
                               
                            }
                        `,
                        fragmentShader: `

                        uniform sampler2D map;

                        varying vec2 vUv;
                        varying float depth;

                        void main() {

                            vec4 color = texture2D( map, vUv );
                            gl_FragColor = vec4( color.r, color.g, color.b, 0.2 );

                        }
                        `,
                        blending: THREE.AdditiveBlending,
                        depthTest: false,
                        depthWrite: false,
                        transparent: true

                    });

                    const pointMesh = new THREE.Points(geometry, material);
                    pointMesh.name = "artistic";
                    pointMesh.visible = false;
                    pointMesh.position.set(0.0, 0.0, 0.0);
                    scene.add(pointMesh);

                    const lineVertices = new Float32Array(w * h * 6);
                    for (let i = 0, j = 0; i < w * h * 3; i += 6, j++) {
                        lineVertices[i] = vertices[i];
                        lineVertices[i + 1] = vertices[i + 1];
                        lineVertices[i + 2] = vertices[i + 2];

                        lineVertices[i + 3] = vertices[i + 3];
                        lineVertices[i + 4] = vertices[i + 4];
                        lineVertices[i + 5] = vertices[i + 5];
                    }

                    const lineGeometry = new THREE.BufferGeometry();
                    lineGeometry.setAttribute('position', new THREE.BufferAttribute(lineVertices, 3));

                    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 2 });
                    const lines = new THREE.LineSegments(lineGeometry, lineMaterial);
                    lines.name = "lines";
                    lines.visible = false;

                    // lines.position.set(0.0, 0.0, 0.0);

                    scene.add(lines);

                    video.play();
                    depthVideo.play();

                },
                runSite = () => {

                    console.log("RUN SITE")

                    // load video
                    const video = document.getElementById('bgvideo'),
                        depthVideo = document.getElementById('bgvideo'),
                        texture = new THREE.VideoTexture(video),
                        depthTexture = new THREE.VideoTexture(depthVideo);

                    texture.minFilter = THREE.NearestFilter;
                    texture.colorSpace = THREE.SRGBColorSpace;
                    depthTexture.minFilter = THREE.NearestFilter;
                    texture.generateMipmaps = false;
                    depthTexture.generateMipmaps = false;

                    console.log('video width height');
                    console.log(video.videoWidth);
                    console.log(video.videoHeight);

                    //const aspectRatio = video.videoWidth / video.videoHeight;
                    const renderGeometry = new THREE.PlaneGeometry(width.value / 16, height.value / 16, width.value / 10, height.value / 10);
                    const planeVertices = renderGeometry.attributes.position.array;

                    // uvs
                    const uvs = renderGeometry.attributes.uv.array;

                    // render material
                    const renderMaterial = new THREE.MeshStandardMaterial({
                        map: texture,
                        side: THREE.BackSide,
                        displacementScale: - 4.0
                    });

                    // extract vertices
                    const w = width.value / 16, h = height.value / 16,
                        nearClipping = 50, farClipping = 100,
                        vertices = new Float32Array(w * h * 6);

                    for (let i = 0, j = 0; i < w * h * 6; i += 6, j++) {

                        // Original vertex
                        vertices[i] = j % w;
                        vertices[i + 1] = Math.floor(j / w);
                        vertices[i + 2] = 0;

                        // Trailing vertex (initially at the same position)
                        vertices[i + 3] = j % w;
                        vertices[i + 4] = Math.floor(j / w);
                        vertices[i + 5] = 0;

                    }

                    const geometry = new THREE.BufferGeometry();
                    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

                    geometryShaderUniforms.value = {

                        'map': { value: depthTexture },
                        'width': { value: w },
                        'height': { value: h },
                        'nearClipping': { value: nearClipping },
                        'farClipping': { value: farClipping },
                        'u_time': { value: 0.0 },
                        'pointSize': { value: 2 },
                        'zOffset': { value: 100 }

                    }

                    const material = new THREE.ShaderMaterial({

                        uniforms: geometryShaderUniforms.value,
                        vertexShader: `

            uniform sampler2D map;

            uniform float width;
            uniform float height;
            uniform float nearClipping, farClipping;

            uniform float pointSize;
            uniform float zOffset;
            uniform float u_time;

            varying vec2 vUv;
            varying float depth;

            const float XtoZ = 1.11146; // tan( 1.0144686 / 2.0 ) * 2.0;
            const float YtoZ = 0.83359; // tan( 0.7898090 / 2.0 ) * 2.0;

            void main() {

                vUv = vec2( position.x / width , position.y / height );

                float z = ( 1.0 - depth ) * (farClipping - nearClipping) + nearClipping;
                depth = z;

                const float XtoZ = 1.11146; // tan( 1.0144686 / 2.0 ) * 2.0;
                const float YtoZ = 0.83359; // tan( 0.7898090 / 2.0 ) * 2.0;

                float bro;

                // Apply sine wave to trailing vertices
                if (int(gl_VertexID) % 2 == 1) {
                  bro = sin(position.x * 0.5 + u_time);
                }

                // gl_Position = projectionMatrix
                // * modelViewMatrix
                // * vec4(position, 1.0);

                vec4 pos = vec4(
                position.x - (width / 2.0) + width / 160.0,
                position.y - (height / 2.0) + height / 160.0,
                bro - z * 10.0 + 980.0,
                1.0);

                gl_PointSize = pointSize;
                gl_Position = projectionMatrix * modelViewMatrix * pos;
               
            }
        `,
                        fragmentShader: `

        uniform sampler2D map;

        varying vec2 vUv;
        varying float depth;

        void main() {

            vec4 color = texture2D( map, vUv );
            gl_FragColor = vec4( color.r, color.g, color.b, 0.2 );

        }
        `,
                        blending: THREE.AdditiveBlending,
                        depthTest: false,
                        depthWrite: false,
                        transparent: true

                    });

                    const pointMesh = new THREE.Points(geometry, material);
                    pointMesh.name = "artistic";
                    pointMesh.visible = true;
                    pointMesh.position.set(0.0, 0.0, 0.0);
                    scene.add(pointMesh);

                    const lineVertices = new Float32Array(w * h * 6);

                    for (let i = 0, j = 0; i < w * h * 3; i += 6, j++) {

                        lineVertices[i] = vertices[i];
                        lineVertices[i + 1] = vertices[i + 1];
                        lineVertices[i + 2] = vertices[i + 2];

                        lineVertices[i + 3] = vertices[i + 3];
                        lineVertices[i + 4] = vertices[i + 4];
                        lineVertices[i + 5] = vertices[i + 5];

                    }

                    const lineGeometry = new THREE.BufferGeometry();
                    lineGeometry.setAttribute('position', new THREE.BufferAttribute(lineVertices, 3));

                    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 2 });
                    const lines = new THREE.LineSegments(lineGeometry, lineMaterial);
                    lines.name = "lines";
                    lines.visible = true;

                    scene.add(lines);

                },
                getRootNode = (node, callback) => {

                    if (node?.parent?.parent == null) {

                        callback(node);

                    } else if (node.parent) {

                        getRootNode(node.parent, callback)

                    } else {

                        callback();

                    }

                },
                handleScroll = (e) => {

                    console.log(e);
                    const scrollTop = window.scrollY;

                    console.log('scrollTop ' + scrollTop)
                    console.log('lastScrollPos ' + lastScrollPos.value)

                    if (scrollTop > lastScrollPos.value) {

                        camera.value.camera.position.z -= 0.03;

                    }

                    if (scrollTop < lastScrollPos.value) {

                        camera.value.camera.position.z += 0.06;

                    }

                    lastScrollPos.value = scrollTop;

                },
                handleResize = () => {

                    width.value = window.innerWidth;
                    height.value = window.innerHeight;

                    // Update camera aspect ratio and renderer size on window resize
                    camera.value.update(width.value, height.value);
                    renderer.setSize(width.value, height.value);
                    composerComponent.value.composer.setSize(width.value, height.value);

                };

            onMounted(() => {

                initScene();

                setTimeout(() => {

                    if (route.path === "/new/") {

                        runVideo();

                    } else {

                        runSite();
                        runScene();

                    }

                    animate(1);
                    //physicsComponent.value.render();

                }, 300);

                createCursor();

                window.addEventListener('resize', handleResize);
                window.addEventListener('scroll', handleScroll);

                //stats.value.showPanel(0);
                //three.value.appendChild(stats.value.dom);

                watch(
                    () => keyPress.value,

                    (first, second) => {

                        console.log('cormac test (keyPress.value.m')
                        console.log(keyPress.value.m)

                        if (keyPress.value.m == true) {

                            loadEntities();

                        }

                        if (keyPress.value['1'] == true) {

                            entityComponent.value.createSphere({ x: 0.5, y: 15, z: 15 }, { x: 0, y: 10, z: 5 });

                        }

                        if (keyPress.value['2'] == true) {

                            entityComponent.value.createBox({ x: 1, y: 1, z: 1 }, { x: 0, y: keyPress.value.m, z: 5 });

                        }

                        if (keyPress.value['0'] == true) {

                            console.log("act.animations");
                            console.log(entities.value)


                            //actionsComponent.value.walk(entities.value.cyberGirl, 'walk');
                            //actionsComponent.value.pistol(entities.value.cyberGirl, 'pistol');
                            const act = entities.value.rabbit;
                            console.log("act");
                            console.log(act);
                            console.log("act.animations");
                            console.log(act.animations);
                            let anim = act.animations["all"];
                            console.log("anim")
                            console.log(anim)
                            act.animationMixer.clipAction(anim).play();

                        }

                        if (keyPress.value['9'] == true) {

                            const act = entities.value["CG| Take 001 | BaseLayer"]
                            act.animationMixer.clipAction(act.animations[1]).play();

                        }

                        if (keyPress.value['8'] == true) {

                            const act = entities.value.cyberGirl;
                            act.animationMixer.clipAction(act.animations[2]).play();

                        }

                        if (keyPress.value['7'] == true) {

                            const act = entities.value.cyberGirl;
                            act.animationMixer.clipAction(act.animations[3]).play();

                        }

                        if (keyPress.value['6'] == true) {

                            const act = entities.value.cyberGirl;
                            act.animationMixer.clipAction(act.animations[4]).play();

                        }

                        if (keyPress.value['5'] == true) {

                            const act = entities.value.cyberGirl;
                            act.animationMixer.clipAction(act.animations[5]).play();

                        }

                        if (keyPress.value['4'] == true) {

                            const act = entities.value.cyberGirl;
                            act.animationMixer.clipAction(act.animations[6]).play();

                        }

                        if (keyPress.value['['] == true) {

                            const act = entities.value.cyberGirl;
                            act.animationMixer.clipAction(act.animations[1]).play();

                        }

                        if (keyPress.value[']'] == true) {

                            const act = entities.value.cyberGirl;
                            for (const i in act.animations) {

                                act.animationMixer.clipAction(act.animations[i]).reset();

                            }

                        }

                        if (keyPress.value.p == true) {

                            // physicsComponent.value.togglePhysicsVisibility();

                        }

                        if (keyPress.value.c == true) {

                            const cam = camera.value.get(),
                                ray = rayCastPointer(),

                                target = ray.vector.unproject(cam);

                            if (!target) {

                                target.copy(new THREE.Vector3(0, 0, -1))

                            }

                            const fireVec = target.clone().sub(cam.position).normalize();

                            entityComponent.value.createSphere({ x: 0.1, y: 10, z: 10 }, cam.position, null, fireVec.multiplyScalar(10));

                        }

                        if (keyPress.value.x == true) {

                            scene.traverse((child) => {

                                if (child.name == "displacement" || child.name == "artistic" || child.name == "lines") {

                                    if (child.visible === true) {

                                        child.visible = false;

                                    } else {

                                        child.visible = true;

                                    }

                                }

                            });

                        }

                    },
                    { deep: true }

                );

                watch(

                    () => mouseInfo.value,

                    (first, second) => {

                        camera.value.orient();

                        const video = document.getElementById('video'),
                            depthVideo = document.getElementById('depthvideo'),
                            bgVideo = document.getElementById('bgvideo');
                            bgVideo.play();

                        if (mouseInfo.value.buttons == 2) {

                            if (video.paused) {

                                video.play();
                                depthVideo.play();

                            } else {

                                video.pause();
                                depthVideo.pause();

                            }
                            const x = rayCastPointer();

                        }

                        console.log(mouseInfo.value)

                        if (route.path === "/new/") {

                            if (mouseInfo.value.deltaX) {

                                camera.value.camera.position.x -= mouseInfo.value.deltaX / 100;

                            }

                            if (mouseInfo.value.deltaY) {

                                camera.value.camera.position.y -= mouseInfo.value.deltaY / 100;

                            }

                        }

                    },
                    { deep: true }

                );

            });

            provide('THREE', THREE);
            provide('scene', scene);
            provide('keyPress', keyPress);
            provide('mouseInfo', mouseInfo);
            provide('renderer', renderer);
            provide('camera', camera);
            //provide('Ammo', ammoLib);
            //provide('utils', utils);

            onUnmounted(() => {

                // Remove event listener and clean up resources

                window.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleResize);
                renderer.dispose();

            });
            /*
            AmmoJS().then((AmmoLib) => {

                ammoLib.value = AmmoLib;
                physicsLoaded.value = true;

            });
            */
            return {
                rootNode,
                route,
                three,
                keyPress,
                axesHelper,
                gridHelper,
                captureMouseInfo,
                captureKeyPress,
                width,
                height,
                currentX,
                currentY,
                deltaX,
                deltaY,
                initScene,
                scene,
                lastScrollPos,
                objects,
                //stats,
                loadEntities,
                addEntity,
                handleResize,
                handleScroll,
                entityComponent,
                //actionsComponent,
                //physicsComponent,
                composerComponent,
                createRigidBody,
                filterIntersects,
                getRootNode,
                addToScene,
                sceneLoaded,
                //physicsLoaded,
                //ammoLib,
                renderer,
                camera,
                cursor,
                entities,
                animate,
                intersected,
                rayCastPointer,
                runVideo,
                runScene,
                displacementShaderUniforms,
                geometryShaderUniforms
            };
        },
    };
</script>

<style>
    body {
        margin: 0;
    }

    #threeContainer {
        width: 100%;
        height: 100vh; /* Adjust as needed */
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .content-container {
        width: 100%;
        height: 100%;
    }
</style>
