<template>
    <div ref="rootNode">
    </div>
</template>

<script>
import { onBeforeUnmount, onMounted, provide, inject, ref, toRaw, watch } from 'vue';
import Controls from './Controls.vue';
import * as THREE from 'three';

export default {
  name: 'Camera',
  "components": {
    Controls,
  },
  setup() {

    const rootNode = ref(),
    keyPress = inject('keyPress'),
    mouseInfo = inject('mouseInfo'),
    //camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000),
    camera = new THREE.PerspectiveCamera(80, window.innerWidth / window.innerHeight, 0.05, 2000),
    cameraPosition = ref(new THREE.Vector3(0,0,0)),
    cameraRotation = ref(new THREE.Quaternion()),
    cameraAngle = ref(0),
    view = ref(0),
    clock = new THREE.Clock(),
    three = ref(null),
    orient = () => {

      if (view.value == "orbit") {
      } else {

        /*  This is the threejs conveience method, but the camera feels .. sloppy.
            Also dragX/Y needs to be reset to zero on mouse off for this to work
            camera.rotateY(THREE.MathUtils.degToRad(mouseInfo.value.dragDeltaX/100));
            camera.rotateX(THREE.MathUtils.degToRad(mouseInfo.value.dragDeltaY/100));
        */

        // Create quaternions for X, Y, and Z rotations
        const qX = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(mouseInfo.value.dragDeltaY/30));
        const qY = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(mouseInfo.value.dragDeltaX/30));
        const qZ = new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(cameraAngle.value));

        // cameraRotation.value = camera.quaternion.clone().multiply(qX).multiply(qY);
        const r = camera.quaternion.clone().multiply(qX).multiply(qY).multiply(qZ);

        camera.setRotationFromQuaternion(r);

      }

    },
    process = function () {

        var x=0, y=0, z=0;

        if (keyPress.value['w'] ===  true) {

            z = -0.3;

        } else if (keyPress.value['s'] ===  true) {

            z = 0.3;

        } else {

            z = 0;

        }

        if (keyPress.value['a'] ===  true) {

            x = -0.3;

        } else if (keyPress.value['d'] ===  true) {

            x = 0.3;

        } else {

            x = 0;

        }

        if (keyPress.value['r'] ===  true) {

            y = 0.3;

        } else if (keyPress.value['f'] ===  true) {

            y = -0.3;

        } else {

            y = 0;

        }

        if (keyPress.value['q'] ===  true) {

            cameraAngle.value = 0.15;
            orient();

        } else if (keyPress.value['e'] ===  true) {

            cameraAngle.value = -0.15;
            orient();

        } else {

            cameraAngle.value = 0;

        }

        let controlVector = new THREE.Vector3(x,y,z);

        if (keyPress.value['shiftKey'] ===  true) {

            controlVector.multiply(new THREE.Vector3(10,10,10));

        }

        cameraPosition.value = controlVector.applyQuaternion(camera.quaternion);

        camera.position.x = THREE.MathUtils.lerp(camera.position.x, camera.position.x + cameraPosition.value.x, 0.1);
        camera.position.y = THREE.MathUtils.lerp(camera.position.y, camera.position.y + cameraPosition.value.y, 0.1);
        camera.position.z = THREE.MathUtils.lerp(camera.position.z, camera.position.z + cameraPosition.value.z, 0.1);

    },
    update = (width, height) => {

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

    },
    get = () => {

        return camera;

    };

    onMounted(() => {

        camera.position.set(0, 0, 0);

        watch(
            () => keyPress.value,

            (first, second) => {

            }

        );

    });

    onBeforeUnmount(() => {

        // Remove event listener and clean up resources
        window.removeEventListener('resize', handleResize);
        renderer.dispose();

    });

    return {
      rootNode,
      three,
      keyPress,
      mouseInfo,
      cameraPosition,
      cameraRotation,
      cameraAngle,
      camera,
      process,
      orient,
      update,
      get,
      view,
      clock
    };
  },
};
</script>

<style>
    body {
        margin: 0;
    }

    #threeContainer {
        width: 100%;
        height: 100vh; /* Adjust as needed */
    }
</style>