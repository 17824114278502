<template>
  <div
    v-if="isLang('ja') > -1"
    :class="docMain"
  >
    <head>
      <title>マツモト様_個人情報保護方針</title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div>
      個人情報保護方針<br>
      当社は、アルバム製作を中心とした総合印刷業務及びNFT（Non-Fungible Token）の<br>マーケットプレイス運営業務を営む会社としての、使命と責任を十分に自覚し、顧客<br>の信頼を得るために、適切な個人情報保護に関する取組みを実行いたします。<br>
      1.&#160;当社は、上記に示す事業の内容及び規模を考慮して、個人情報を適切に<br>
      取得・管理し、利用いたします。当社では、あらかじめご本人の同意を得る<br>ことなく利用目的の達成に必要な範囲を超えた個人情報の取り扱いを行<br>わないことを原則とし、そのための措置を講じます。<br>
      2.&#160;当社は、ご本人の同意を得た場合や法令等により正当な理由がある場合<br>
      を除き、個人データを第三者に提供いたしません。<br>
      3.&#160;当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範<br>
      を遵守します。<br>
      4.&#160;当社は、保有する個人情報の漏えい、滅失又はき損を防止するため、対<br>
      策を講じるとともに、必要な是正措置を講じます。<br>
      5.&#160;当社は、個人情報の取扱い及び個人情報保護マネジメントシステムに関<br>
      する苦情・相談窓口を設置し、対応いたします。<br>
      6.&#160;当社は、ＪＩＳ規格（ＪＩＳＱ１５００１）に基づく個人情報保護マネジメントシス<br>
      テムを運用し、継続的に見直し、改善していきます。<br>
      制定日：H23年6月2日<br>最終改訂日：R5年1月31日<br>株式会社マツモト<br>代表取締役社長　松本　大輝<br>
      個人情報の取り扱いについて<br>株式会社マツモト<br>個人情報保護管理者　徳永和敏<br>（連絡先は下記の窓口と同じです）<br>当社は、お取引に伴いお客様の個人情報を取り扱うことがございます。当社は、個人情報保護の<br>重要性を深く認識するとともに、「個人情報保護方針」に従い、以下に記載のとおり、個人情報を<br>適切に取り扱います。<br>
      個人情報の取り扱いに対する当社の基本姿勢<br>
      当社は、個人情報保護方針を宣言するとともに、当社の従業者、その他関係者に周知徹底させて<br>実行し、改善・維持してまいります。<br>
      また、個人情報の取得にあたっては、適法かつ公正な手段によって行い、不正な方法によって取<br>得しないことはもちろん、個人情報の主体である本人に対し個人情報を与えることの任意性及び<br>当該情報を与えなかった場合に本人に生じる結果を通知いたします。<br>
      <hr>
      <a name="2" />当社が取扱う個人情報とその利用目的<br>
      当社が取扱う個人情報<br>
      利用目的<br>
      <ul>
        <li>
          卒業アルバム、記念誌、ダイレク<br>トメール等の印刷若しくは発送又<br>は当社の提供するNFTマーケット<br>プレイスにおけるサービス提供そ<br>の他当社の業務を提供するため<br>
        </li>
        <li>
          当社の業務に関するお問合せへ<br>の対応のため<br>
        </li>
        <li>
          当社の業務に関する当社からの<br>
          お客様の個人情報<br>
          ご案内・情報提供を行うため<br>
        </li>
        <li>
          お客様へのサービス提供のため<br>
        </li>
        <li>
          当社の広告、宣伝又はマーケティ<br>ングを行うため<br>
        </li>
        <li>
          当社ウェブサイト等の安全な運営<br>に必要な不正対策のため<br>
        </li>
        <li>
          個人情報保護方針所定の方法に<br>よる第三者への提供を行うため<br>
        </li>
      </ul>
      採用応募者より取得した個人情報<br>
      従業者の採用選考のため<br>
      従業者（退職者を含みます。）より取得<br>
      従業者の雇用管理その他必要な連<br>
      した個人情報<br>
      絡等のため<br>
      上記以外の目的で個人情報を利用させていただく場合には、法令で認められている場合を除き、<br>ご本人様の同意をいただくものとします。<br>
      個人データの第三者への非開示・非提供<br>当社は、次に掲げる場合を除くほか、あらかじめご本人様の同意を得ないで、保有する個人デー<br>タを第三者に開示又は提供しません。なお、お客様が当社の運営するNFTマーケットプレイスを通<br>じて、デジタルチケットを表章するＮＦＴを購入した場合、当該デジタルチケットによって参加するこ<br>とのできるイベントの主催者等に対して、お客様の同意を得て一定のお客様の個人データを提供<br>すること又はお客様から直接イベントの主催者等に対してご自身の個人情報をご提供いただくこ<br>とがあります。<br>
      1.<br>
      法令に基づく場合<br>
      <hr>
      <a name="3" />2.<br>
      人の生命，身体又は財産の保護のために必要がある場合であって，本人の同意を<br>得ることが困難であるとき<br>
      3.<br>
      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であっ<br>て，本人の同意を得ることが困難であるとき<br>
      4.<br>
      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂<br>行することに対して協力する必要がある場合であって，本人の同意を得ることによっ<br>て当該事務の遂行に支障を及ぼすおそれがあるとき<br>
      個人データの提供及び受領時の記録等<br>個人データを第三者（国の機関、地方公共団体、独立行政法人等及び地方独立行政法人を除<br>き、以下、本項において同様とします。）に提供し、又は第三者から受領する場合は、個人情報保<br>護法に従って、必要な事項について記録（以下「第三者提供記録」といいます。）を作成するととも<br>に、個人情報保護委員会規則で定められた期間保存いたします。<br>
      当社が取り扱う個人情報の安全対策<br>
      1.<br>
      当社は個人情報の漏えい、滅失又はき損等を防止するため、個人情報の取扱いに<br>係る基本方針及び必要な社内規程を整備し、実行するほか、従業者への教育・訓<br>練、責任者の権限の明確化、管理区域の入退室管理、アクセス制限の実施、外部委<br>託先の管理・監督等、必要かつ適切な安全管理措置を講じます。ただし、不正なアク<br>セスを防止する絶対的な技術が存在しないことをご了解ください。<br>
      2.<br>
      当社は、取得した個人データを正確かつ最新の内容を保つとともに、利用する必要<br>がなくなった時は、当該個人データを遅滞なく消去するように努めます。<br>
      個人情報保護方針の変更手続<br>当社は、個人情報の取扱いを適切に行うため、個人情報保護方針及び「個人情報の取り扱いに<br>ついて」を随時見直し、改訂いたします。<br>
      開示、訂正・利用停止、苦情等の対応窓口<br>当社が個人情報保護法に基づき保有する保有個人データについて、利用目的の通知・開示・利<br>用の停止・消去・第三者への提供の停止を請求、第三者提供記録の開示・訂正・追加・削除・利<br>用の停止・消去及び保有個人データの訂正・追加・削除（以下、「開示等の請求」といいます。）を<br>請求することができます。開示等の請求に際しては、ご本人様であることを確認させていただくた<br>めに、本人確認書類（免許証・パスポート等）の提示等をお願いする場合がありますので、予めご<br>了承ください。ただし、お申し出が個人情報保護法の定める要件を満たさない場合又は個人情報<br>保護法その他の法令により開示等を拒絶することが認められる事由がある場合には、お申し出に<br>添えないことがございます。また、個人情報に該当するか否かを問わず、人事評価等にかかわる<br>情報の開示は基本的にお断りしておりますので、ご了承ください。開示等の請求の手続きについ<br>ての詳細は、「個人情報保護に関するお問合せ先」までお問い合わせください。なお、当社が保有<br>する保有個人データの利用目的の通知、当社が保有する保有個人データの開示の請求又は、第<br>三者提供記録の開示の請求を受けたときは、1件あたり1000円の手数料をご請求させていただき<br>ます。<br>
      そのほか、お客様は、下記のお客様専用窓口に対し、当社の個人情報の取扱い及び個人情報保<br>護マネジメントに関する問い合わせを行うことが出来ます。<br>
      当該窓口では、お客様からの問い合わせを受け付けた場合は、開示等の請求の手続について当<br>該お客様にお知らせし、合理的な範囲で適切かつ迅速に対応させていただきます。<br>
      <hr>
      <a name="4" />記<br>
      <ul>
        <li>
          個人情報保護に関するお問合せ先<br>
        </li>
        <li>
          〒800-8555　福岡県北九州市門司区社ノ木1-2-1<br>
        </li>
        <li>
          株式会社マツモト　個人情報保護外部対応窓口<br>個人情報保護管理者　徳永和敏<br>
        </li>
      </ul>
      <a href="tel:093-371-0298">TEL: 093-371-0298</a><br>
      認定個人情報保護団体の名称：<br>
      <br>
      一般財団法人日本情報経済社会推進協会<br>
      <br>
      苦情の解決の申出先：<br>
      <br>
      個人情報保護苦情相談室<br>
      <br>
      住所：<br>
      <br>
      〒106-0032 東京都港区六本木一丁目９番９号六本木ファーストビル内<br>
      <br>
      電話番号：<br>
      <br>
      03-5860-7565、0120-700-779<br>
      <br>
      【株式会社マツモトの商品・サービスに関する問合せ先ではございません】<br>
      以上<br>
      制定日：H23年6月2日<br>最終改訂日：R5年1月31日<br>
      <hr>
    </div>
  </div>
  <div
    v-else
    :class="docMain"
  >
    <head>
      <title>マツモト様_個人情報保護方針</title>
    </head>
    <div>
      <h1 class="doc">
        Personal Information Protection Policy
      </h1>
      At our company, we are dedicated to providing general printing services, with a focus on album production and NFT (Non-Fungible Token) marketplace operations. We understand our responsibilities to protect personal information and have taken the necessary measures to gain the trust of our customers.

      <br>
      <br>
      We acquire, manage, and use personal information in an appropriate manner, taking into account the nature and scale of our business. We only handle personal information that is necessary to achieve the intended purpose, and we obtain prior consent from the relevant individual.

      <br>
      <br>
      We do not provide personal data to third parties unless we have obtained the consent of the relevant individual, or if there is a legal requirement to do so.
      <br>
      <br>

      We comply with all laws, regulations, national guidelines, and other norms related to the handling of personal information.
      <br>
      <br>

      We take the necessary measures and corrective actions to prevent the leakage, loss, or damage of personal information.
      <br>
      <br>

      We have established a complaint and consultation center regarding the handling of personal information and the personal information protection management system, and respond accordingly.
      <br>
      <br>

      We operate our personal information protection management system based on the Japanese Industrial Standards (JISQ15001) and continually review and improve it.

      <br>
      <br>
      Date of enactment: June 2, 2011
      <br>
      <br>
      Last revised date: January 31, 2023
      <br>
      <br>
      MATSUMOTO INC.
      <br>
      <br>
      Daiki Matsumoto, President and Chief Executive Officer
      <br>
      <br>

      Handling of Personal Information
      <br>
      <br>

      MATSUMOTO INC.
      <br>
      <br>
      Kazutoshi Tokunaga, Personal Information Protection Administrator
      <br>
      <br>

      We may handle your personal information in connection with transactions. We recognize the importance of protecting personal information and handle it appropriately in accordance with our Personal Information Protection Policy.
      <br>
      <br>

      Our basic stance on the handling of personal information is as follows:
      <br>
      <br>
      <br>

      We declare our Personal Information Protection Policy and ensure that our employees and related parties are fully aware of it.
      <br>
      <br>
      We acquire personal information by lawful and fair means and will not acquire it by illegal means.
      <br>
      <br>
      We inform individuals of the voluntary nature of providing personal information and the consequences of not doing so.
      <br>
      <br>
      We handle personal information for the following purposes:

      <br>
      <br>
      <br>
      To print or send graduation albums, commemorative publications, direct mail, or other materials, or to provide services on our NFT marketplace or other services.
      <br>
      <br>
      To respond to inquiries about our operations.
      <br>
      <br>
      To provide guidance and information regarding our operations from us.
      <br>
      <br>
      To provide services to our customers.
      <br>
      <br>
      To advertise, promote or market our company.
      <br>
      <br>
      To take anti-fraud measures necessary for the safe operation of our website, etc.
      <br>
      <br>
      To provide information to a third party in the manner set forth in the Personal Information Protection Policy.
      <br>
      <br>
      If we use personal information for purposes other than those listed above, we will ask for consent unless otherwise permitted by law.

      <br>
      <br>
      Non-disclosure/Non-provision of Personal Data to Third Parties

      We do not disclose or provide your personal data to any third party without obtaining prior consent from you, except in the following cases:

      <br>
      <br>
      If it is necessary based on laws and regulations.
      <br>
      <br>
      If it is necessary for the protection of life, body, or property of any individual and it is difficult to obtain your consent.
      <br>
      <br>
      If it is particularly necessary for the improvement of public health or the promotion of the sound upbringing of children and it is difficult to obtain your consent.
      <br>
      <br>
      If it is necessary to cooperate with any governmental institution or local government or a person entrusted by them in the performance of affairs prescribed by the laws and regulations, and obtaining your consent may interfere with the performance of such affairs.
      <br>
      <br>

      Procedures for changing the Personal Information Protection Policy
      <br>
      <br>
      In order to handle personal information appropriately, we review and revise our Personal Information from time to time.
      <br>
      <br>

      <h2>Contact center for disclosure, correction/suspension of use and complaints</h2>
      With respect to the personal data held by us under the Act on the Protection of Personal Information, youmay request the notification, disclosure, suspension of use, elimination and suspension of provision to athird party of the purpose of use, disclosure, correction, addition, deletion, suspension of use andelimination of the Third Party Provision Records, as well as correction, addition, and deletion of theretained personal data (the Request for Disclosures). When making the Request for Disclosures, wemay ask you to present your identification documents (including driver's license and passport) to confirmyour identity. However, if your request does not meet the requirements set forth in the Act on theProtection of Personal Information or if there are any grounds to refuse disclosures under the Act on theProtection of Personal Information or other laws and regulations, we may not accept your request. Inaddition, it should be noted that we basically refuse to disclose information related to personnelevaluations regardless of whether such information falls under the category of personal information. Formore information about the procedures for the Request for Disclosures, please contact the Contac tInformation for Personal Information Protection below. When we receive a request for notification of the purpose of use or a request for disclosure of the retained personal data held by our company, or a requestfor disclosure of the Third Party Provision Records, we will charge a fee of 1,000 yen per case.
      In addition, you can make inquiries about our handling of personal information and personal informationprotection management at the following customer support contact center.
      Upon receiving an inquiry from you, the contact center will inform you of the procedures for the Requestfor Disclosures, and will respond to it appropriately and promptly to the reasonable extent.
      Note
      <h2>Contact Information for Personal Information Protection</h2>
      <ul>
        <li>
          1 -2 -1 Yashinoki, Moji-ku, Kitakyushu, Fukuoka 800-8555
        </li>
        <li>
          Personal Information Protection External Contact Center, MATSUMOTO INC.Kazutoshi Tokunaga, Personal Information Protection Administrator
        </li>
      </ul>
      <br>
      TEL: 093-371-0298
      <br>
      Name of authorized personal information protection organization:
      <br>
      JIPDEC
      <br>
      Contact for resolving complaints:
      <br>
      Personal Information Protection Consultation Service Office
      <br>
      Address:
      <br>
      Roppongi First Building, 1-9-9 Roppongi, Minato-ku, Tokyo, 106-0032
      <br>
      Phone:
      <br>
      03-5860-7565, 0120-700-779
      <br>
      <br>
      <a name="4" />*[This is not a contact information for the products and services of MATSUMOTO INC.]
      END
      Date of enactment: June 2, 2011Last revised date: January 31, 2023
      <br>
    </div>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Privacy",
    "components": {
    },
    "props": {
    },
    setup () {

        const icon = iconStore(),
            serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            "icon": icon.get,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>
