<template>
  <div
    ref="rootNode"
  >
    <div
      tabindex="0"
      class="cover"
      @keydown="keyDownHandler"
      @keyup="keyUpHandler"
      @mousemove="mouseMoveHandler"
      @mousedown="mouseDownHandler"
      @mouseup="mouseUpHandler"
      @mouseover="mouseOverHandler"
      @mouseleave="mouseLeaveHandler"
    />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';

export default {
  name: 'Controls',
  "components": {
  },
  "emits": [
      "key-press",
      "mouse-info",
  ],
  setup (props, {emit}) {
    const rootNode = ref(),
    deltaX = ref(0),
    deltaY = ref(0),
    dragDeltaX = ref(0),
    dragDeltaY = ref(0),
    currentX = ref(0),
    currentY = ref(0),
    keyPress = ref({}),
    mouseInfo = ref({}),
    mouseMoveHandler = (e) => {

      // persist draggable
      e.preventDefault()

      const clientX = e.clientX,
       clientY = e.clientY;

      deltaX.value = currentX.value - clientX;
      deltaY.value = currentY.value - clientY;

      if (e.buttons === 1) {

        dragDeltaX.value = currentX.value - clientX;
        dragDeltaY.value = currentY.value - clientY;

      } else {

        dragDeltaX.value = 0;
        dragDeltaY.value = 0;

      }

      currentX.value = clientX;
      currentY.value = clientY;

      mouseInfo.value = {
        clientX,
        clientY,
        deltaX:deltaX.value,
        deltaY:deltaY.value,
        dragDeltaX:dragDeltaX.value,
        dragDeltaY:dragDeltaY.value,
        mouseOver: false,
        buttons: e.buttons
      };

      emit('mouse-info', mouseInfo);

    },
    mouseOverHandler = () => {
    
        mouseInfo.value.mouseOver = true;

    },
    mouseLeaveHandler = () => {

        mouseInfo.value.mouseOver = false;

        for (const key in keyPress.value) {

            keyPress.value[key] = false;

        }

        console.log('keyPress.value')
        console.log(keyPress.value)

        emit('keyPress', keyPress);

    },
    mouseDownHandler = (e) => {

        mouseInfo.value.buttons = e.buttons;
        emit('mouse-info', mouseInfo);

    },
    mouseUpHandler = (e) => {

        mouseInfo.value.buttons = e.buttons;
        emit('mouse-info', mouseInfo);

    },
    keyDownHandler = (event) => {

        const key = event.key.toLowerCase();
        keyPress.value[key] = true;
        keyPress.value.shiftKey = event.shiftKey;
        emit('keyPress', keyPress);

    },
    keyUpHandler = (event) => {

        const key = event.key.toLowerCase();
        keyPress.value[key] = false;
        keyPress.value.shiftKey = event.shiftKey;
        emit('keyPress', keyPress);

    };

    onMounted(() => {

    });

    onBeforeUnmount(() => {

    });

    return {
      rootNode,
      keyDownHandler,
      keyUpHandler,
      keyPress,
      mouseMoveHandler,
      mouseOverHandler,
      mouseLeaveHandler,
      mouseDownHandler,
      mouseUpHandler,
      mouseInfo,
      currentX,
      currentY,
      deltaX,
      deltaY,
      dragDeltaX,
      dragDeltaY
    };
  },
};
</script>

<style>
.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  -webkit-appearance: none;
}

</style>